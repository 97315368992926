<script setup>
import logoIcon from '@images/static/icon.png';

import { computed, onMounted, ref } from "vue";

import { useAjaxForm } from "@/utils/form";
import { getReferralCode, getPlatoon } from "@/utils";

import AppButton from '@/components/Button/Button.vue';
import Error from '@/components/Form/Error.vue';
import FormButton from '@/components/Form/FormButton.vue';
import FormInput from '@/components/Form/FormInput.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';

const emit = defineEmits(['success', 'cancel']);

const modal = ref(null);

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    response: {
        type: Object,
        default: null,
    },
    providers: {
        type: Array,
        required: true,
    }
});

const provider = computed(() => props.providers.find(provider => provider.key === props.response?.provider));

const form = useAjaxForm({
    password: '',
});

form.retrievesCsrf();

form.onSuccess((response) => {
    emit('success', response.data.data);
    emit('update:open', false);
});
</script>

<template>
    <Modal :open="open" hide-close ref="modal">
        <ModalTitle show>
            <h1 class="text-2xl font-semibold text-center">
                <template v-if="response?.status === 'requires_approval'">
                    Link your account with {{ provider.label }}
                </template>
                <template v-else-if="response?.status === 'requires_signup'">
                    Confirm signup with {{ provider.label }}
                </template>
            </h1>
        </ModalTitle>
        <div v-if="open" class="w-full mt-8">
            <div class="flex w-full justify-center items-center gap-2 mb-6">
                <img :src="provider.icon" :alt="provider.label" class="w-10 h-10 rounded-full">
                <span class="text-3xl font-semibold">+</span>
                <img :src="logoIcon" alt="logo" class="w-10 h-10 rounded-full">
            </div>
            <form v-if="response.status === 'requires_approval'" @submit.prevent="form.post('/auth/social/complete', { data: { token: response.token } })">
                <p class="text-center">
                    Please enter your Corperland's account password for <span class="font-semibold">{{ response.email }}</span> to
                    link your account with {{ provider.label }} for the first time
                </p>

                <div class="mt-10">
                    <FormInput :form="form" name="password" label="Password" type="password" />
                </div>

                <div class="mt-10 grid grid-cols-2 gap-4">
                    <AppButton type="button" @click="emit('update:open', false)" color="gray" rounded-full>Cancel</AppButton>
                    <FormButton :form="form" type="submit" full color="primary" roundedFull>Login</FormButton>
                </div>
            </form>

            <form v-if="response.status === 'requires_signup'" @submit.prevent="form.post('/auth/social/complete', {
                data: {
                    token: response.token,
                    platoon: getPlatoon(),
                    referral_code: getReferralCode(),
                }
            })">
                <p class="text-center">
                    An account will be created for you with the email <span class="font-semibold text-primary">{{ response.email }}</span>,
                    Do you wish to continue?
                </p>

                <p class="mt-10 text-sm">
                    By signing up, you confirm that you have read and agreed to Corperland's <a target="_blank" href="/agreements/privacy-policy" class="text-primary">Privacy Policy</a>
                    and <a target="_blank" href="/aggrements/eula" class="text-primary">End-User License Agreement</a>
                </p>

                <div class="mt-10">
                    <div class="mb-4">
                        <Error :value="form.errors.platoon" />
                        <Error :value="form.errors.referral_code" />
                    </div>

                    <div class="grid grid-cols-2 gap-4">
                        <AppButton type="button" @click="emit('update:open', false)" color="gray" capitalize rounded-full>Cancel</AppButton>
                        <AppButton :loading="form.processing" type="submit" full color="primary" rounded-full capitalize>Create Account</AppButton>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>

<script setup>
import dayjs from 'dayjs';
import { markRaw, ref, getCurrentInstance, computed } from 'vue';

import { useToast } from '@/plugins/toast';
import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { useEmitter } from '@/plugins/emitter';
import { deepCopy } from '@/utils';

import Alert from '@/components/Modals/Alert.vue';
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import EditPostModal from '@/components/Post/EditPostModal.vue';
import IconCopy from '@/components/Icons/Copy.vue';
import IconEdit from '@/components/Icons/Edit.vue';
import IconHide from '@/components/Icons/Hide.vue';
import IconMoreVertical from '@/components/Icons/MoreVertical.vue';
import IconShare from '@/components/Icons/Share.vue';
import IconTrash from '@/components/Icons/TrashCanOutline.vue';
import useClient from '@/plugins/client.js';

defineEmits(['deleted']);

const props = defineProps({
    post: {
        required: true,
        type: Object,
    },
});

const emitter = useEmitter();
const instance = getCurrentInstance();
const toast = useToast();
const store = useStore();
const client = useClient();
const deleteForm = useAjaxForm();
const hideForm = useAjaxForm();
const confirmDelete = ref(false);
const confirmHide = ref(false);
const showEditModal = ref(false);
const ajax = useAjaxForm();

deleteForm.onSuccess(() => {
    toast.success('Post deleted successfully.');
    confirmDelete.value = false;
    instance.emit('deleted');
    emitter.emit('.post:deleted', deepCopy(props.post));
});

const deletePost = (alert) => {
    alert.setProcessing(true);
    deleteForm.delete(`/api/posts/${props.post.id}`, {
        onFinally: () => {
            alert.setProcessing(false);
        }
    });
}

const hidePost = (alert) => {
    alert.setProcessing(true);
    hideForm.post(`/api/posts/${props.post.id}/hide`, {
        onSuccess: () => {
            props.post.status = 4;
            emitter.emit('.post:hidden', props.post);
            confirmHide.value = false;
        },
        onFinally: () => {
            alert.setProcessing(false);
        }
    });
}

const pinPost = () => {
    if (props.post.pinned_at) {
        const initialPinnedAt = props.post.pinned_at;
        props.post.pinned_at = null;
        ajax.delete(`/api/pinned-posts/${props.post.id}`, {
            onSuccess: () => {
                emitter.emit('.post:unpinned', props.post);
                emitter.emit('.channel:post-unpinned', props.post.channel);
            },
            onError: () => {
                props.post.pinned_at = initialPinnedAt;
            }
        });
    } else {
        props.post.pinned_at = dayjs().toISOString();
        ajax.post(`/api/pinned-posts`, {
            data: {
                post_id: props.post.id,
            },
            onSuccess: () => {
                emitter.emit('.post:pinned', props.post);
                emitter.emit('.channel:post-pinned', props.post.channel);
            },
            onError: () => {
                props.post.pinned_at = null;
            }
        });
    }
}

const actions = computed(() => {
    return [
        {
            label: 'Pin to Channel',
            icon: 'icon-pin-outline',
            as: 'button',
            onSelect() {
                pinPost();
            },
            show: !props.post.pinned_at && props.post.channel.user_membership?.role_abilities['posts:pin'] === true,
        },
        {
            label: 'Unpin',
            icon: 'icon-pin-off-outline',
            as: 'button',
            onSelect() {
                pinPost();
            },
            show: props.post.pinned_at && props.post.channel.user_membership?.role_abilities['posts:pin'] === true,
        },
        {
            label: 'Share',
            icon: markRaw(IconShare),
            onSelect() {
                client.navigator.share({
                    url: props.post.url,
                })
            },
            as: 'button',
            show: !!client.navigator?.share
        },
        {
            label: 'Copy',
            icon: markRaw(IconCopy),
            onSelect(e) {
                let content = props.post.title ? props.post.title + '\n' : '';
                content += (props.post.content ?? '');

                content = content.replace(/<br>/g, '\n');

                client.navigator.clipboard.writeText(content)
                    .then(() => {
                        toast.publish({
                            title: 'Text copied',
                            color: 'success',
                        });
                    });
            },
            as: 'button',
            show: !!client.navigator?.clipboard,
        },
        {
            label: 'Edit',
            icon: markRaw(IconEdit),
            as: 'button',
            onSelect() {
                showEditModal.value = true;
            },
            show: props.post.acl.can_edit &&  props.post.type_name != 'POLL',
            disabled: !props.post.edits_allowed,
        },
        {
            label: 'Delete',
            icon: markRaw(IconTrash),
            as: 'button',
            onSelect() {
                confirmDelete.value = true;
            },
            show: props.post.acl.can_delete,
        },
        {
            label: 'Hide',
            icon: markRaw(IconHide),
            as: 'button',
            onSelect() {
                confirmHide.value = true;
            },
            show: props.post?.channel?.user_membership?.role_abilities['posts:hide'] === true,
        },
        {
            label: 'Report',
            icon: 'icon-flag',
            as: 'button',
            onSelect() {
                store.reportContent('post', deepCopy(props.post));
            },
            show: props.post?.channel?.user_membership?.role_abilities['content:report'] === true
                && store.state.user
                && props.post.user?.id != store.state.user.id,
        },
        {
            label: 'Block User',
            icon: 'icon-block',
            onSelect() {
                store.blockUser({
                    user: props.post.user,
                    anonymous: props.post.anonymously,
                    source_id: props.post.id,
                    source_type: 'post',
                });
            },
            show: props.post.user?.id != store.state.user.id,
        },
    ];
});
</script>

<template>
    <DropdownMenu :items="actions" content-align="end">
        <DropdownMenuTrigger class="flex py-2 justify-center items-center gap-2">
            <icon-more-vertical width="20" height="20" />
            <span class="sr-only">
                More options
            </span>
            <Alert
                danger
                v-model:open="confirmDelete"
                @confirmed="deletePost"
                confirm-text="Delete"
                title="Delete Post"
                description="Are you sure you want to delete this post? This action is irreversible." />
            <Alert
                danger
                v-model:open="confirmHide"
                @confirmed="hidePost"
                confirm-text="Hide"
                title="Hide Post">
                <div>
                    This post will be hidden from this channel. Users will no longer be able to see or interact with this post.
                </div>
            </Alert>

            <EditPostModal @done="showEditModal = false" v-if="showEditModal" :open="showEditModal" @update:open="showEditModal = false" :post="post" />
        </DropdownMenuTrigger>
    </DropdownMenu>
</template>

<script setup>
import { computed, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { deepCopy } from '@/utils';

import ModalBottomSheet from '@/components/BottomSheets/ModalBottomSheet.vue';

const filterModalOpen = ref(false);

const route = useRoute();
const router = useRouter();

const feed = inject('feed');
const defaults = ref({
    types: [],
});

const filters = ref({});

const isDirty = () => {
    return JSON.stringify(filters.value) !== JSON.stringify(defaults.value);
}

const openModal = () => {
    filters.value = deepCopy(defaults.value);
    filterModalOpen.value = true;
}

const applyFilters = () => {
    filterModalOpen.value = false;

    router.push({
        name: 'channels.show.filter',
        params: {
            handle: route.params.handle,
        },
        query: {
            filters: btoa(JSON.stringify(filters.value)),
        },
    }).then(() => {
        filters.value = deepCopy(defaults.value);
    });
}

const toggleType = (type) => {
    if (filters.value.types.includes(type)) {
        filters.value.types = filters.value.types.filter(t => t !== type);
    } else {
        filters.value.types.push(type);
    }
}

const selectOne = (key) => {
    filters.value.mentions = undefined;
    filters.value.saved = undefined;
    filters.value.pins = undefined;

    filters.value[key] = true;
}

const selectAuthor = (author) => {
    filters.value.author = author;
}

const cancelClear = () => {
    if (isDirty()) {
        filters.value = { ...defaults.value };
        return;
    }

    filterModalOpen.value = false;
}
</script>

<template>
    <app-icon-button @click="openModal" type="button" aria-label="Filter contents" icon="filter-variant">
        <ModalBottomSheet v-model:open="filterModalOpen">
            <div class="px-4 pt-8 pb-4">
                <h1 class="text-lg font-medium">Filters</h1>
            </div>
            <app-list dense>
                <app-list-item @click="selectOne('pins')" :selected="filters.pins">
                    <app-list-content :headline="`Pinned (${feed.resource.data.pinned_posts_count})`" />
                    <app-list-trailing radio />
                </app-list-item>
                <app-list-item @click="selectOne('mentions')" :selected="filters.mentions">
                    <app-list-content headline="Mentions" />
                    <app-list-trailing radio />
                </app-list-item>
                <app-list-item @click="selectOne('saved')" :selected="filters.saved">
                    <app-list-content headline="Saved" />
                    <app-list-trailing radio />
                </app-list-item>
            </app-list>

            <hr class="h-0 border-white-foreground/10 my-4" />

            <app-list dense>
                <app-list-item @click="toggleType(1)" :selected="filters.types.includes(1)">
                    <app-list-content headline="Posts" />
                    <app-list-trailing checkbox />
                </app-list-item>
                <app-list-item @click="toggleType(2)" :selected="filters.types.includes(2)">
                    <app-list-content headline="Questions" />
                    <app-list-trailing checkbox />
                </app-list-item>
                <app-list-item @click="toggleType(3)" :selected="filters.types.includes(3)">
                    <app-list-content headline="Polls" />
                    <app-list-trailing checkbox />
                </app-list-item>
            </app-list>

            <hr class="h-0 border-white-foreground/10 my-4" />

            <app-list dense>
                <app-list-item @click="selectAuthor(
                    filters.author !== $store.state.user.id ? $store.state.user.id : undefined
                )" :selected="filters.author === $store.state.user.id">
                    <app-list-content headline="Created by Me" />
                    <app-list-trailing checkbox />
                </app-list-item>
            </app-list>

            <div class="px-4 pb-4 flex justify-end gap-4 mt-10">
                <AppButton @click="cancelClear" capitalize>{{ isDirty() ? 'Clear' : 'Cancel' }}</AppButton>
                <AppButton @click="applyFilters" color="primary" capitalize :disabled="!isDirty() && !route.query.filters">Apply</AppButton>
            </div>
        </ModalBottomSheet>
    </app-icon-button>
</template>

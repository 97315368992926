import { onMounted, reactive, watch } from 'vue';
import dayjs from 'dayjs';

import { collectionV2, resourceV2 } from '@/composables/resource';
import { useStore } from '@/store';
import { mem_store } from '@/utils/cache';
import { useChannelListener, usePostListener } from '@/composables/listeners';
const MAX_CACHE_SIZE = 15;

export const useFeed = (handle) => {
    const store = useStore();
    const cache = mem_store('channel-feed', MAX_CACHE_SIZE);
    const key = (store.state.user?.id ?? '') + ':' + handle;

    return cache.remember(key, ({ onEvicted }) => {
        const channelListener = useChannelListener();
        let listener = null;
        const resource = resourceV2({ url: `/api/channels/${handle}` });
        const posts = collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    channel: handle,
                },
            },
        });

        const filteredPosts = posts.clone();

        const newPostsCb = [];

        let intersectedPosts = [];
        let handleIntersectedPosts = null;
        let pendingNewPosts = [];
        let handlePostPublished = null;

        // when the feed was first loaded
        let lastPostSeenAt = null;

        const state = reactive({
            resource,
            posts,
            windowY: null,
            newPosts: [],
            unSeenPosts: [],
            filters: null,
            filteredPosts,
            postIntersected(postId) {
                clearTimeout(handleIntersectedPosts);

                if (!intersectedPosts.includes(postId)) {
                    intersectedPosts.push(postId);
                }

                handleIntersectedPosts = setTimeout(() => {
                    state.newPosts = state.newPosts.filter(postId => !intersectedPosts.includes(postId));
                    state.unSeenPosts = state.unSeenPosts.filter(postId => !intersectedPosts.includes(postId));
                    intersectedPosts = [];
                }, 500);
            },
            onNewPost(cb) {
                newPostsCb.push(cb);
            },
        });

        watch(() => state.resource.fetched, (value) => {
            if (!value) {
                return;
            }

            if (!lastPostSeenAt) {
                lastPostSeenAt = dayjs(state.resource.data.user_membership?.last_post_seen_at);
            }

            if (!listener) {
                listener = channelListener.listen({
                    channel: state.resource.data,
                    postPublished: (data) => {
                        try {
                            clearTimeout(handlePostPublished);
                            pendingNewPosts.unshift(data);

                            handlePostPublished = setTimeout(() => {

                                state.posts.unshift(...pendingNewPosts);

                                const newPosts = pendingNewPosts.map(post => post.id);

                                state.newPosts.unshift(...newPosts);
                                pendingNewPosts = [];
                            }, 500);
                        } catch (error) {
                            console.error(error);
                        }
                    },
                });
            }
        });

        state.posts.onDataFetched((data) => {
            data.forEach(post => {
                if (!state.unSeenPosts.includes(post.id) && lastPostSeenAt?.isBefore(post.published_at)) {
                    state.unSeenPosts.push(post.id);
                }
            });
        });

        const postListener = usePostListener({
            getPost: (id) => {
                return state.posts.data.find(post => post.id === id);
            },
            getPosts: () => {
                return state.posts.data;
            },
            postDeletedCb: (post) => {
                setTimeout(() => {
                    state.posts.delete(post.id);
                }, 5000);
            },
            postHiddenCb: (data) => {
                const post = state.posts.data.find(post => post.id === data.id);

                if (post.user.id !== store.state.user.id) {
                    setTimeout(() => {
                        state.posts.delete(post.id);
                    }, 5000);
                }
            },
        });

        onMounted(() => {
            postListener.on();
        });

        onEvicted(() => {
            listener?.off();
            postListener.off();
        });

        return state;
    });
}

export const useJoinedChannels = () => {
    const store = useStore();
    const key = (store.state.user?.id ?? '') + ':joined-channels';
    const cache = mem_store('joined-channels', 1);

    return cache.remember(key, ({ onEvicted }) => {
        const listener = useChannelListener();
        const channels = collectionV2({
            url: '/api/channels',
            params: {
                view: 'joinedChannels',
                with_unseen_posts_count: true,
            }
        });

        const listeners = {};

        channels.onDataFetched((data) => {
            data.forEach(channel => {
                const listen = listener.listen({
                    channel: channels.find(channel.id),
                });

                listeners[channel.id] = listen;
            });
        });

        channels.onItemDeleted((item) => {
            listeners[item.id]?.off();
            delete listeners[item.id];
        });

        onMounted(() => {
            channels.fetchOnce();
        });

        onEvicted(() => {
            Object.values(listeners).forEach(listener => {
                listener.off();
            });
        });

        return channels;
    });
}

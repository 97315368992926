import axios from '@/axios';
import { computed, ref, reactive } from "vue";

import { useToast } from "@/plugins/toast";
import { useStore } from '@/store';

import { deepCopy } from '@/utils';

const cache = reactive({});

export function postCollection(config, remember = false) {
    return collection({...config, ...{ url: '/api/posts' }
    }, remember);
}

export function useUserNotifications() {
    return collection('/api/notifications', 'user-notifications');
}

export function myFeedPosts() {
    return
}

function collectionDefaultState() {
    return {
        data: [],
        links: {},
        meta: {},
        fetching: false,
        fetched: false,
    };
}

/**
 * @param {Object} config
 * @param {String} config.url
 * @param {Object} config.params
 * @param {Object} config.response
 */
export function resourceV2(config) {
    const state = reactive({
        fetching: false,
        fetched: false,
        status: null,
        statusMessage: null,
        response: null,
        error: null,
    });

    if (config.response) {
        state.response = config.response;
        state.fetched = true;
        state.status = 200;
    }

    return {
        fill(data) {
            state.response = data;
            state.fetched = true;
            state.status = 200;
        },
        get data() {
            return state.response?.data;
        },
        get response() {
            return state.response;
        },
        get error() {
            return state.error;
        },
        get fetched() {
            return state.fetched;
        },
        get fetching() {
            return state.fetching;
        },
        get status() {
            return state.status;
        },
        async fetch({ params, headers, swCache = false } = {}) {
            state.fetching = true;
            params = params ?? config.params;
            headers = (headers ?? config.headers) ?? {};

            if (swCache) {
                headers['SW-STRATEGY'] = '1';
            }

            return new Promise((resolve, reject) => {
                axios.get(config.url, { params, headers })
                    .then((response) => {
                        state.response = response.data;
                        state.fetched = true;
                        state.status = response.status;

                        resolve(this.response);
                    })
                    .catch((error) => {
                        state.error = error;
                        state.fetched = false;
                        state.status = error.response?.status;

                        reject(this.error);
                    }).finally(() => state.fetching = false);
            });
        },
        async fetchOnce() {
            if (state.fetched) {
                return new Promise((resolve) => resolve(this.response));
            }

            return this.fetch();
        }
    };
}

/**
 * @param {Object} config
 * @param {String} config.url
 * @param {Object} config.params
 * @param {Function} config.transformCb
 */
export function collectionV2({ url, params, transformCb, resolveDataUsing, uniqueById }) {
    const state = ref(collectionDefaultState());
    let defaultUrl = url;
    let defaultParams = params ?? {};
    const onDataFetchedCbs = [];
    const onItemDeletedCbs = [];
    const onItemAddedCbs = [];

    const fetched = async (response, replaceData = true) => {
        const body = response.data;

        const originalData = body.data;
        let data = body.data;

        if (uniqueById) {
            const existingIds = new Set(state.value.data.map(item => item.id));
            data = data.filter(item => !existingIds.has(item.id));
        }

        if (transformCb) {
            data = await transformCb(body.data);
        }

        if (replaceData) {
            state.value.data = data;
        } else {
            state.value.data.push(...data);
        }

        onDataFetchedCbs.forEach(cb => cb(data, originalData));

        state.value.links = body.links;
        state.value.meta = body.meta;

        if (!state.value.fetched) {
            state.value.fetched = true;
        }

        state.value.fetching = false;
    }

    const get = async (getUrl, getParams, replaceData = true, swCache = false) => {
        return new Promise((resolve, reject) => {
            state.value.fetching = true;

            axios.get(getUrl, {
                params: getParams,
                headers: {
                    'SW-STRATEGY': swCache ? '1' : undefined,
                },
            })
            .then(async (response) => {
                await fetched(response, replaceData);
                resolve(true);
            })
            .catch((error) => {
                state.value.fetching = false;
                reject(error);
            });
        });
    }

    return {
        setState(newState) {
            state.value = newState;

            return this;
        },
        get state() {
            return state.value;
        },
        newCollection() {
            return collectionV2({
                url: defaultUrl,
                params: defaultParams,
                transformCb: transformCb,
                resolveDataUsing: resolveDataUsing,
                uniqueById: uniqueById,
            });
        },
        clone() {
            const clone = this.newCollection();

            clone.setState(deepCopy(state.value));

            return clone;
        },
        onDataFetched(cb) {
            onDataFetchedCbs.push(cb);
        },
        onItemAdded(cb) {
            onItemAddedCbs.push(cb);
        },
        onItemDeleted(cb) {
            onItemDeletedCbs.push(cb);
        },
        setUrl(url) {
            defaultUrl = url;
        },
        setParams(params) {
            for (let key in params) {
                defaultParams[key] = params[key];
            }

            return this;
        },
        resetParams() {
            defaultParams = params ?? {};
        },
        find(id) {
            return state.value.data.find(item => item.id === id);
        },
        where(key, value) {
            return state.value.data.filter(item => item[key] === value);
        },
        idExists(id) {
            return state.value.data.some((data) => data.id == id);
        },
        push(...data) {
            state.value.data.push(...data);
            onItemAddedCbs.forEach(cb => {
                try {
                    cb(data);
                } catch (error) {
                    console.error(error);
                }
            });
        },
        unshift(...data) {
            state.value.data.unshift(...data);
            onItemAddedCbs.forEach(cb => {
                try {
                    cb(data);
                } catch (error) {
                    console.error(error);
                }
            });
        },
        delete(id) {
            const deletedItem = state.value.data.find(item => item.id === id);

            if (!deletedItem) {
                return;
            }

            state.value.data = state.value.data.filter(item => item.id !== id);
            onItemDeletedCbs.forEach(cb => {
                try {
                    cb(deletedItem);
                } catch (error) {
                    console.error(error);
                }
            });
        },
        get data() {
            if (resolveDataUsing) {
                return resolveDataUsing(state.value.data);
            }

            return state.value.data;
        },
        get meta() {
            return state.value.meta;
        },
        get links() {
            return state.value.links;
        },
        get fetching() {
            return state.value.fetching;
        },
        get fetched() {
            return state.value.fetched;
        },
        setFetched(value) {
            state.value.fetched = value;
        },
        reset() {
            state.value = collectionDefaultState();
        },
        async refresh() {
            this.reset();

            return this.fetch();
        },

        async fetch(url = null, params = null, swCache = false) {
            return get(url ? url : defaultUrl, params ? params : defaultParams, true, swCache);
        },

        async fetchOnce() {
            if (state.value.fetched) {
                return new Promise((resolve) => resolve(true));
            }

            return this.fetch();
        },

        async nextCursor() {
            if (state.value.links.next) {
                return get(state.value.links.next, defaultParams, false);
            }

            return new Promise((resolve, reject) => {
                reject(new Error('No next page.'));
            });
        },

        async next() {
            if (state.value.links.next) {
                return get(state.value.links.next, defaultParams, true);
            }

            return new Promise((resolve, reject) => {
                reject(new Error('No next page.'));
            });
        },

        async prev() {
            if (state.value.links.prev) {
                return get(state.value.links.prev, defaultParams, true);
            }

            return new Promise((resolve, reject) => {
                reject(new Error('No previous page.'));
            });
        },
    };
}

export function collection(url, remember = false) {
    let filter = {};
    let response = null;
    if (typeof url === 'object') {
        filter = url.filter ? url.filter : {};
        response = url.response;
        url = url.url;
    }

    let cacheKey = url + '@' + JSON.stringify(filter);

    if (remember && typeof remember === 'string') {
        cacheKey = remember;
    }

    const store = useStore();

    if (remember && store.state.user) {
        cacheKey += '@' + store.state.user.id;
    }

    if (cache[cacheKey]) {
        return cache[cacheKey];
    }

    const data = [];
    const links = {
        first: null,
        last: null,
        next: null,
        prev: null,
    };

    const meta = {
        next_cursor: null,
        path: null,
        per_page: 15,
        prev_cursor: null,
    };

    const loading = false;
    const fetched = false;
    const hasMore = computed(() => links.next);
    const dataCount = 0;

    let transformCb = null;

    const ret = reactive({
        data,
        links,
        meta,
        filter,
        loading,
        fetching: loading,
        fetched,
        hasMore,
        dataCount,
        isEmpty: fetched && dataCount < 1,
        fetchUrl: url,
        nextFetched: false,
        _startLoading() {
            this.loading = true;
        },
        _stopLoading() {
            setTimeout(() => this.loading = false, 100);
        },
        transformUsing(cb) {
            transformCb = cb;
        },
        async fillFrom(response, shouldDelay = false) {
            this.links.first = response.links.first;
            this.links.last = response.links.last;
            this.links.next = response.links.next;
            this.links.prev = response.links.prev;

            this.meta.next_cursor = response.meta.next_cursor;
            this.meta.path = response.meta.path;
            this.meta.per_page = response.meta.per_page;
            this.meta.prev_cursor = response.meta.prev_cursor;

            let data = response.data;

            if (transformCb) {
                data = transformCb(response.data);
            }

            this.data.push(...data);
            this.fetched = true;

            this.dataCount = this.data.length;
            this.isEmpty = this.fetched && this.dataCount < 1;

            return this;
        },
        _get(url) {
            this._startLoading();

            return axios.get(url, {
                params: this.filter
            })
            .then((response) => this.fillFrom(response.data, true))
            .then(() => this._stopLoading());
        },
        resetResponse() {
            this.fetched = false;
            this.data = [];
            this.links = {
                first: null,
                last: null,
                next: null,
                prev: null,
            };

            this.meta = {
                next_cursor: null,
                path: null,
                per_page: 15,
                prev_cursor: null,
            };
        },
        fetch() {
            if (this.loading) {
                return;
            }

            this.resetResponse();
            return this._get(this.fetchUrl);
        },
        pull() {
            //
        },
        fetchOnce() {
            if (this.fetched) {
                return;
            }

            return this.fetch();
        },
        nextCursor() {
            return this.next();
        },
        next() {
            if (!this.links.next) {
                return false;
            }

            this.nextFetched = true;

            return this._get(this.links.next);
        },
        prev() {
            if (!this.links.prev) {
                return false;
            }

            return this._get(this.links.prev);
        },
        find(id) {
            return this.data.filter((item) => item.id === id)[0];
        },
        exists(id) {
            return this.data.some((item) => item.id === id);
        },
        add(data) {
            if (!this.exists(data.id)) {
                this.data.unshift(data);
            }

            return this.find(data.id);
        },
    });

    if (response) {
        ret.fillFrom(response);
    }

    if (remember) {
        cache[cacheKey] = ret;
    }

    return ret;
}

export function useChannelResource(handle) {
    return useResource(`/api/channels/${handle}`);
}

export function useProfileResource(username) {
    return useResource(`/api/profiles/${username}`);
}

export function usePostResource(id) {
    return useResource(`/api/posts/${id}`);
}

export function useResource(url) {
    const toast = useToast();
    const state = reactive({
        filled: false,
        fetching: false,
        fetchStatus: null,
    });

    return reactive({
        getFetchStatus() {
          return state.fetchStatus;
        },
        fetching() {
            return state.fetching;
        },
        filled() {
            return state.filled;
        },
        fill(data) {
            for (let key in data) {
                this[key] = data[key];
            }

            state.filled = true;
        },
        async fetchOnce({ onsuccess, onerror, cache } = {}) {
            if (state.filled) {
                return;
            }

            return this.fetch({ onsuccess, onerror, cache });
        },
        async fetch({ onsuccess, onerror, cache } = {}) {
            state.fetching = true;

            return axios.get(url, {
                    headers: {
                        'SW-STRATEGY': cache ? '1' : undefined,
                    }
                })
                .then((response) => {
                    this.fill(response.data);
                    state.fetchStatus = response.status;

                    if (onsuccess) {
                        onsuccess(response.data);
                    }
                })
                .catch((error) => {
                    if (onerror) {
                        onerror(error);
                    }

                    if (error.response) {
                        state.fetchStatus = error.response.status;

                        if (error.response.status === 400) {
                            toast.publish({
                                title: error.response.data.message || 'Invalid request.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 401) {
                            toast.publish({
                                title: 'Unauthenticated.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 403) {
                            toast?.publish({
                                title: 'Unauthorized request.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 500) {
                            toast?.publish({
                                title: 'An error occured. Please try again later.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 404) {
                            toast?.publish({
                                title: 'Resource not found.',
                                color: 'danger',
                            });
                        } else {
                            toast?.publish({
                                title: 'An error occured. Please try again later.',
                                color: 'danger',
                            });

                            throw new Error(error.response.data.message);
                        }
                    } else if (error.request) {
                        toast.publish({
                            title: 'Request failed.',
                            color: 'danger',
                        });
                    }
                })
                .finally(() => state.fetching = false);
        },
    });
}

<script setup>
// Usage Guidelines: https://m3.material.io/components/icon-buttons/guidelines

import { computed, resolveComponent } from "vue";

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    selected: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    buttonStyle: {
        type: String,
        default: 'standard',
    },
    color: {
        type: String,
        default: 'primary',
    },
    type: {
        type: String,
        default: 'button',
    },
});

const standardStateLayerStyles = () => {
    const styles = [];

    if (!props.selected) {
        styles.push(
            'text-white-foreground',
            props.disabled ? '' : 'hover:bg-white-foreground/10 focus:bg-white-foreground/20 active:bg-white-foreground/20',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    } else {
        styles.push(
            props.color === 'primary' ? 'text-primary' : '',
            !props.disabled && props.color === 'primary' ? 'hover:bg-primary/10 focus:bg-primary/20 active:bg-primary/20' : '',
            props.disabled ? 'text-white-foreground opacity-[0.38]' : '',
        );
    }

    return styles.join(' ');
}

const filledStateLayerStyles = () => {
    const styles = [];

    return styles.join(' ');
}

const filledTonalStateLayerStyles = () => {
    const styles = [];

    return styles.join(' ');
}

const outlinedStateLayerStyles = () => {
    const styles = [];

    if (!props.selected) {
        styles.push(
            'border border-white-foreground/50',
            props.disabled ? '' : 'hover:bg-white-foreground/10 focus:bg-white-foreground/20 active:bg-white-foreground/20',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    } else {
        styles.push(
            props.disabled ?
                'bg-white-foreground/10 text-white-foreground' :
                'bg-white-foreground text-white',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    }

    return styles.join(' ');
}

const stateLayerStyles = computed(() => {
    if (props.buttonStyle === 'standard') {
        return standardStateLayerStyles();
    }

    if (props.buttonStyle === 'filled') {
        return filledStateLayerStyles();
    }

    if (props.buttonStyle === 'filled-tonal') {
        return filledTonalStateLayerStyles();
    }

    if (props.buttonStyle === 'outlined') {
        return outlinedStateLayerStyles();
    }

    return '';
});

const getIconComponent = (icon, defaultIcon) => {
    const component = resolveComponent(icon);
    return typeof component === 'string' ? defaultIcon : icon;
};
</script>

<template>
    <button class="size-12 flex items-center justify-center" :disabled="disabled" :type="type">
        <div class="size-10 rounded-full flex items-center justify-center" :class="stateLayerStyles">
            <component :is="selected ? 'icon-' + icon : getIconComponent('icon-' + icon + '-outline', 'icon-' + icon)" class="size-6" />
            <slot />
        </div>
    </button>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { useStore } from '@/store';
import { useAjaxForm } from '@/utils/form';
import { useEmitter } from '@/plugins/emitter';
import { deepCopy } from '@/utils';

const store = useStore();
const form = useAjaxForm();
const emitter = useEmitter();

const open = ref(false);
const data = computed(() => store.state.blockUser);

const closeDialog = () => {
    open.value = false;
    setTimeout(() => {
        store.commit('blockUser', null);
    }, 300);
}

const no = () => {
    closeDialog();
    data.value.done({
        success: false,
    });
}

const blockUser = () => {
    let { user, source_id, source_type, anonymous, done, unblock } = data.value;

    if (unblock) {
        form.delete(`/api/blocked-users/${user.id}`, {
            onSuccess: () => {
                try {
                    emitter.emit('.user:unblocked', {
                        user: deepCopy(user),
                    });
                } catch (error) {
                    console.error(error);
                }

                closeDialog();
                done({
                    success: true,
                });
            },
        });
    } else {
        form.post('/api/blocked-users', {
            data: {
                user: user?.id,
                source_id,
                source_type,
            },
            onSuccess: () => {
                try {
                    emitter.emit('.user:blocked', {
                        anonymous,
                        source_id,
                        source_type,
                        user: deepCopy(user),
                    });
                } catch (error) {
                    console.error(error);
                }

                closeDialog();
                done({
                    success: true,
                });
            },
        });
    }
}

const userData = computed(() => {
    if (data.value.anonymous) {
        return {
            name: 'Anonymous',
            profile: {
                username: 'anonymoususer',
            }
        };
    }

    return data.value.user;
});

watch(() => store.state.blockUser, (value) => {
    open.value = Boolean(value);
}, { immediate: true });
</script>

<template>
    <div>
        <app-dialog :open="open" close-on-back-navigation basic-width @update:open="closeDialog">
            <template v-if="data">
                <app-dialog-body>
                    <app-dialog-title>{{ data.unblock ? 'Unblock' : 'Block' }} {{ userData.name }} (@{{ userData.profile.username }})?</app-dialog-title>

                    <app-dialog-description v-if="!data.unblock">
                        Blocking this user will prevent them from connecting with or messaging you on corperland.
                        You will also not be able to see their posts or comments in all feeds.
                    </app-dialog-description>
                    <app-dialog-description v-else>
                        Unblocking this user will allow them to connect with or message you on corperland.
                    </app-dialog-description>
                </app-dialog-body>
                <app-dialog-footer>
                    <app-text-button @click="no">No</app-text-button>
                    <app-text-button @click="blockUser">Yes, {{ data.unblock ? 'Unblock' : 'Block' }}</app-text-button>
                </app-dialog-footer>
            </template>
        </app-dialog>
    </div>
</template>

<script setup>
import newEntries from '@images/illustrations/new-entries.svg';

import { computed, inject, nextTick, ref, watch } from 'vue';
import dayjs from 'dayjs';

import { getFriendlyTime } from '@/utils';

import IconLoader from '@/components/Icons/Loader.vue';
import Observer from '@/components/Observer.vue';
import FeedPostItem from '@/views/channels/_handle_/_helpers/FeedPostItem.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

const feed = inject('feed');

const props = defineProps({
    enableTracking: {
        type: Boolean,
        default: false,
    },
    trackingSource: {
        type: String,
        required: false,
    },
    collection: {
        type: Object,
        required: true,
    },
});

const contentListContainer = ref(null);

const posts = computed(() => {
    return props.collection.data.sort((a, b) => {
        return a.published_at_timestamp - b.published_at_timestamp;
    });
});

const showDate = (post, index) => {
    if (index === 0) {
        return true;
    }

    const previousPost = posts.value[index - 1];

    const isSameDay = dayjs(post.published_at).isSame(dayjs(previousPost.published_at), 'day');

    return !isSameDay;
};

const newPostId = ref(null);

const fetchOldContents = () => {
    if (!props.collection.links.next || props.collection.fetching) {
        return;
    }

    const oldScrollHeight = contentListContainer.value.scrollHeight;

    props.collection.nextCursor().finally(() => {
        const currentScrollPosition = document.scrollingElement.scrollTop;

        nextTick(() => {
            const newScrollHeight = contentListContainer.value.scrollHeight;
            const newContentsHeight = newScrollHeight - oldScrollHeight;

            document.scrollingElement.scrollTop = currentScrollPosition + newContentsHeight;
        });
    });
}

watch(() => feed?.newPosts, (value) => {
    if (!feed) {
        return;
    }

    if (value.length <= 0) {
        newPostId.value = null;

        return;
    }

    if (!newPostId.value) {
        newPostId.value = value[value.length - 1];
    }
}, { immediate: true, deep: true });

defineEmits(['postDeleted']);

let fetchTimeout = null;
const fetchingOldContents = ref(false);

const startFetchOldContents = () => {
    clearTimeout(fetchTimeout);
    fetchTimeout = setTimeout(() => {
        fetchOldContents();
    }, 1000);
}

const clearFetchOldContents = () => {
    clearTimeout(fetchTimeout);
}
</script>

<template>
    <div class="px-2">
        <Observer
            v-if="collection.fetched && collection.data.length"
            class="h-9 w-full flex items-center justify-center"
            aria-live="polite"
            @intersected="startFetchOldContents"
            @unintersected="clearFetchOldContents"
        >
            <template v-if="collection.links.next">
                <span class="sr-only">Loading older contents</span>
                <IconLoader class="size-7" />
            </template>
            <template v-else-if="!collection.links.next">
                <div class="flex items-center gap-2 w-full">
                    <div class="grow h-[1px] bg-white-foreground/20"></div>
                    <div aria-hidden="true" class="uppercase text-xs text-white-foreground/50 font-medium shrink-0">End</div>
                    <span class="sr-only">No older contents</span>
                </div>
            </template>
        </Observer>
        <Skeleton :collection="collection" />
        <ul ref="contentListContainer" class="flex flex-col gap-2 pb-[66px]" v-if="collection.data && collection.data.length > 0">
            <template v-for="post, index in posts" :key="post.id">
                <li class="flex flex-col gap-1" :data-post-id="post.id">
                    <Transition name="fade">
                        <div v-if="newPostId === post.id" class="flex items-center gap-2 w-full transition-all duration-1000">
                            <div class="grow h-[1px] bg-primary"></div>
                            <div class="uppercase text-xs text-primary font-medium shrink-0">new</div>
                        </div>
                    </Transition>
                    <div v-if="showDate(post, index)" class="text-xs text-white-foreground/80 bg-white-foreground/10 px-2 py-1 rounded-lg mx-auto">
                        {{ getFriendlyTime(post.published_at, { withTime: false, todayString: 'Today' }) }}
                    </div>
                </li>
                <FeedPostItem :post="post" :enable-tracking="enableTracking" :tracking-source="trackingSource" />
            </template>
        </ul>
        <div
            v-if="collection.fetched && collection.data.length === 0"
            class="flex flex-col items-center justify-center mt-20 px-4 py-6 rounded-md">
            <img :src="newEntries" loading="lazy" class="size-[300px]" />
        </div>
    </div>
</template>

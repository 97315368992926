<script setup>
import { useStore } from '@/store';

import { PostType } from '@/enums';

import ThumbGallery from '@/components/File/ThumbGallery.vue';
import LineClamp from '@/components/LineClamp.vue';
import PostContent from '@/components/Post/PostContent.vue';
import PostFooter from '@/components/Post/PostFooter.vue';
import PostHeader from '@/components/Post/PostHeader.vue';
import PostLink from '@/components/Link/PostLink.vue';
import PostPoll from '@/components/Post/PostPoll.vue';

const store = useStore();

const props = defineProps({
    post: {
        type: Object,
        required: true,
    },
    as: {
        type: String,
        default: 'li',
    },
    trackingSource: {
        type: String,
        required: false,
    },
    enableTracking: {
        type: Boolean,
        default: false,
    },
});

const getTrackingData = () => {
    if (!props.enableTracking) {
        return null;
    }

    if (props.post.deleted ) {
        return null;
    }

    if (store.state.user && store.state.user.id === props.post.user?.id) {
        return null;
    }

    return {
        source: props.trackingSource,
        item_id: props.post.id,
        item_type: 'post',
    };
};



</script>

<template>
    <component :is="as">
        <div v-track-event:view="getTrackingData">
            <template v-if="post.deleted || (post.status === 4 && post.user.id !== $store.state.user.id)">
                <div class="bg-white text-white-foreground p-4 flex flex-col md:rounded-md border border-white-foreground/10">
                    <p class="italic">This content has been deleted</p>
                </div>
            </template>
            <template v-else-if="post.reported || post.blocked">
                <div class="bg-white text-white-foreground/70 p-4 flex flex-col md:rounded-md border border-white-foreground/10">
                    <span class="text-sm font-medium">This content is hidden because you flagged it or blocked the user.</span>
                </div>
            </template>
            <template v-else>
                <article class="bg-white text-white-foreground p-4 flex flex-col md:rounded-md border border-white-foreground/10">
                    <PostHeader :post="post" />

                    <PostLink class="block" :post="post">

                        <LineClamp :lines="6" class="mt-2">
                            <PostContent :post="post" />
                        </LineClamp>
                    </PostLink>

                    <component :is="post.user_vote || post.poll_expired ? PostLink : 'div'" :post="post" class="mt-2 block">
                        <PostPoll v-if="post.type === PostType.POLL" :post="post" />
                    </component>

                    <ThumbGallery v-if="post.files" class="mt-2" :files="post.files" :item="post" :gallery-id="'post-' + post.id" />
                    <PostFooter class="mt-2" :post="post" />
                </article>
            </template>
        </div>
    </component>
</template>

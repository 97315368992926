<script setup>
import { DialogRoot, DialogPortal, DialogContent, DialogOverlay } from 'radix-vue';
import { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue';

import { useDialogs } from '@/plugins/dialogs';
import { useStore } from '@/store';

const emit = defineEmits(['update:open']);

const props = defineProps({
    open: {
        type: Boolean,
        default: false
    },
    fullScreen: {
        type: Boolean,
        default: false
    },
    forceFullScreen: {
        type: Boolean,
        default: false
    },
    teleportTo: {
        type: String,
        required: false,
    },
    closeOnBackNavigation: {
        type: Boolean,
        default: true
    },
    basicWidth: {
        type: Boolean,
        default: false,
    },
    nonDismissible: {
        type: Boolean,
        default: false,
    },
    disableAutoFocus: {
        type: Boolean,
        default: false,
    },
});

const dialogs = useDialogs();
const store = useStore();

const onEscapeKeyDown = (e) => {
    if (props.nonDismissible) {
        e.preventDefault();
    }
}

const onInteractOutside = (e) => {
    if (props.nonDismissible) {
        e.preventDefault();
    }
}

const onOpenAutoFocus = (e) => {
    if (props.disableAutoFocus) {
        e.preventDefault();
    }
}

const isFullScreenMode = computed(() => {
    return props.fullScreen && (store.state.deviceType === 'mobile' || props.forceFullScreen);
});

const contentClass = computed(() => {
    const classes = [];

    if (isFullScreenMode.value) {
        classes.push(
            'top-0 right-0 h-screen max-md:w-screen md:min-w-[350px] lg:min-w-[450px]',
            'animate-in duration-300 data-[state=closed]:animate-out',
            'data-[state=open]:slide-in-from-right data-[state=closed]:slide-out-to-right',
        );

        if (props.basicWidth) {
            classes.push('md:max-w-[450px] lg:max-w-[500px]');
        }
    } else {
        classes.push(
            'top-1/2 left-1/2 max-h-[85vh] min-w-[280px] max-w-[560px] -translate-x-1/2 -translate-y-1/2 rounded-2xl',
        );

        if (props.basicWidth) {
            classes.push('w-[312px]');
        }
    }

    return classes.join(' ');
});

const dialogCloseOnBackNavigation = () => {
    emit('update:open', false);
}

const canCloseOnBackNavigation = computed(() => {
    return props.closeOnBackNavigation && !props.nonDismissible;
});

watch(() => props.open, (dialogOpened) => {
    if (!dialogOpened) {
        dialogs.unregister(dialogCloseOnBackNavigation);
        return;
    }

    if (!canCloseOnBackNavigation.value) {
        return;
    }

    dialogs.register(dialogCloseOnBackNavigation);
});

provide('isFullScreenMode', isFullScreenMode);

onMounted(() => {
    if (canCloseOnBackNavigation.value && props.open) {
        dialogs.register(dialogCloseOnBackNavigation);
    }
});

onUnmounted(() => {
    dialogs.unregister(dialogCloseOnBackNavigation);
});
</script>

<template>
    <DialogRoot :open="open" @update:open="emit('update:open', $event)">
        <DialogPortal :to="teleportTo ? teleportTo : 'body'">
            <DialogOverlay class="
                bg-black/20 animate-in duration-300 data-[state=closed]:animate-out
                data-[state=open]:fade-in data-[state=closed]:fade-out
                fixed inset-0 z-[100]
            " />
            <DialogContent
                @escapeKeyDown="onEscapeKeyDown"
                @interactOutside="onInteractOutside"
                @openAutoFocus="onOpenAutoFocus"
                class="fixed focus:outline-none z-[100] bg-white flex flex-col"
                :class="contentClass"
            >
                <slot />
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>

<script setup>
import { useAjaxForm } from '@/utils/form';
import { computed } from 'vue';

import { registerFileUploader } from '@/views/files/_helpers/index';

import AppButton from '@/components/Button/Button.vue';
import FormInput from '@/components/Form/FormInput.vue';
import Radio from '@/components/Form/Radio.vue';
import Switch from '@/components/Form/Switch.vue';
import Uploader from '@/views/files/Uploader.vue';

const props = defineProps({
    channel: {
        default: null,
        type: Object,
        required: false,
    }
});

const form = useAjaxForm({
    name: props.channel?.name,
    handle: props.channel?.handle,
    description: props.channel?.description,
    is_public: Boolean(props.channel ? props.channel.is_public : true),
    posting_disabled: Boolean(props.channel ? props.channel.posting_disabled : false),
    image: props.channel?.image,
});

const handleHelp = computed(() => 'Your channel will be accessed via https://corperland.ng/channels/' + (form.handle ? form.handle : '{channel-handle}'));

defineExpose({
    form,
    canSubmit: computed(() => form.isClean() || form.processing),
});

registerFileUploader({
    queryMode: true,
});
</script>

<template>
    <div>
        <form @submit.prevent="$emit('form-submitted')">
            <FormInput :form="form" as="avatar" name="image" :input-bind="{
                ariaLabel: 'Avatar',
                size: '3xl',
            }" />

            <FormInput :form="form" name="name" />
            <FormInput :form="form" name="handle" :help="handleHelp" lowercase />
            <FormInput
                :form="form"
                name="description"
                as="textarea"
                :input-bind="{ maxRows: 10, rows: 4 }"
                help="Make sure to be descriptive and clear about the purpose of this channel. Your channel could be rejected if the purpose is unclear."
            />

            <Switch v-model="form.posting_disabled" class="mt-8" id="posting_disabled">
                <div>Disable Posting</div>
                <div class="mt-2 text-sm opacity-80 leading-tight">
                    When posting is disabled only administrators and moderators will be able to post to this channel.
                </div>
            </Switch>

            <div class="text-sm block cursor-pointer font-semibold mt-4">Type</div>
            <div class="flex flex-col gap-4 mt-2">
                <Radio v-model="form.is_public" name="is_public" :value="true" id="is_public.yes">
                    <div class="font-medium">
                        Public
                    </div>
                    <div class="text-sm opacity-80 leading-tight mt-0.5">
                        This channel will be publicly visible and anyone can join. Non-members can view posts, but cannot comment or publish posts.
                    </div>
                </Radio>

                <Radio v-model="form.is_public" name="is_public" :value="false" id="is_public.no">
                    <div class="font-medium">
                        Private
                    </div>
                    <div class="text-sm opacity-80 leading-tight mt-0.5">
                        Private channel requires a user to request to become a member. Only members can publish posts, view posts, and comment on posts.
                    </div>
                </Radio>
            </div>
        </form>
        <Uploader v-if="$route.query.modal === 'file-uploader'" />
    </div>
</template>

import AppList from './List.vue';
import AppListContent from './ListContent.vue';
import AppListItem from './ListItem.vue';
import AppListLeading from './ListLeading.vue';
import AppListTrailing from './ListTrailing.vue';

export default {
    AppList,
    AppListContent,
    AppListItem,
    AppListLeading,
    AppListTrailing,
}

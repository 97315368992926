<script setup>
import { onMounted } from 'vue';

import { useRegister } from '@/composables/actions';
import { getPlatoon, getReferralCode } from '@/utils';
import { useToast } from '@/plugins/toast';

import AppButton from '@/components/Button/Button.vue';
import Error from '@/components/Form/Error.vue';
import IconCancelBold from '@/components/Icons/CancelBold.vue';
import FormInput from '@/components/Form/FormInput.vue';
import SocialButtons from '@/components/Auth/SocialButtons.vue';
import Checkbox from '@/components/Form/Checkbox.vue';
import PrivacyLink from '@/components/PrivacyLink.vue';

const toast = useToast();
const register = useRegister({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    privacy_policy: false,
    platoon: null,
    referral_code: null,
});

const removePlatoon = () => {
    register.form.platoon = null;
    localStorage.removeItem('platoon');
    toast.info('Platoon removed. Your account will be created without a platoon.');
};

const removeReferralCode = () => {
    register.form.referral_code = null;
    localStorage.removeItem('referral_code');
    toast.info('Referral code removed. Your account will be created without a referral code.');
};

onMounted(() => {
    register.form.platoon = getPlatoon();
    register.form.referral_code = getReferralCode();
});
</script>

<template>
    <div class="mt-6 w-full">
        <div v-if="register.form.platoon" class="my-2 flex gap-2 items-center">
            <div class="font-semibold">Platoon {{ register.form.platoon }}</div>
            <button aria-label="Remove platoon" type="button" @click="removePlatoon">
                <IconCancelBold class="size-4" />
            </button>
        </div>
        <div v-if="register.form.referral_code" class="my-2 flex gap-2 items-center">
            <div class="font-semibold">
                Referred by: <span class="text-primary">AMB {{ register.form.referral_code }}</span>
            </div>
            <button aria-label="Remove referral code" type="button" @click="removeReferralCode">
                <IconCancelBold class="size-4" />
            </button>
        </div>
        <SocialButtons />

        <form @submit.prevent="register.execute()" class="mt-5">
            <FormInput :form="register.form" name="name" label="Full Name" />
            <FormInput :form="register.form" name="email" label="Email" type="email" />
            <FormInput :form="register.form" name="password" label="Password" type="password" />
            <FormInput :form="register.form" name="password_confirmation" label="Re-type Password" type="password" />

            <p class="mt-10 text-sm">
                By signing up, you confirm that you have read and agreed to Corperland's <a target="_blank" href="/agreements/privacy-policy" class="text-primary">Privacy Policy</a>
                and <a target="_blank" href="/agreements/eula" class="text-primary">End-User License Agreement</a>
            </p>

            <AppButton full color="primary" type="submit"
                :loading="register.form.processing" class="mt-8">
                Register
            </AppButton>
        </form>

        <p class="mt-8 text-gray-foreground/60 text-center">
            Already have an account? <RouterLink class="text-primary font-semibold" :to="{ name: 'login' }">Login
            </RouterLink>
        </p>
    </div>
</template>

<script setup>
import newEntries from '@images/illustrations/new-entries.svg';

import { computed, nextTick, onMounted, onUnmounted, inject, ref, provide, watch } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

import axios from '@/axios';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';
import { useJoinedChannels, useFeed } from '@/composables/channel';
import { useModalRouter } from '@/modal-router';

import Avatar from '@/components/Avatar.vue';
import AppButton from '@/components/Button/Button.vue';
import ChannelLink from '@/components/Link/ChannelLink.vue';
import Content from '@/views/_partials/Content.vue';
import FeedBottombar from '@/views/channels/_handle_/_helpers/FeedBottombar.vue';
import FeedPostList from '@/views/channels/_handle_/_helpers/FeedPostList.vue';
import Filter from '@/views/channels/_handle_/_helpers/Filter.vue';
import IconAccountLock from '@/components/Icons/AccountLock.vue';
import IconArrowUpThick from '@/components/Icons/ArrowUpThick.vue';
import IconBan from '@/components/Icons/Ban.vue';
import IconBookmark from '@/components/Icons/Bookmark.vue';
import IconFilterVariant from '@/components/Icons/FilterVariant.vue';
import IconMoreVertical from '@/components/Icons/MoreVertical.vue';
import IconPencilOff from '@/components/Icons/PencilOff.vue';
import IconPencilPlus from '@/components/Icons/PencilPlus.vue';
import IconSearch from '@/components/Icons/Search.vue';
import Header from '@/views/_partials/header/Header.vue';
import ModalBottomSheet from '@/components/BottomSheets/ModalBottomSheet.vue';
import Pill from '@/components/Pills/Pill.vue';
import PillGroup from '@/components/Pills/PillGroup.vue';

const store = useStore();
const route = useRoute();
const modalRouter = useModalRouter();
const requestReview = ref(false);
const requestReviewSubmitting = ref(false);
const toast = useToast();

const joinedChannels = useJoinedChannels();

const feed = useFeed(route.params.handle);

const clearUnseenPosts = () => {
    const joinedChannel = joinedChannels.data.find(channel => channel.handle === route.params.handle);

    if (joinedChannel) {
        joinedChannel.user_membership.total_unseen_posts = 0;
    }

    axios.post(`/api/members/${feed.resource.data.user_membership.id}/clear-unseen-posts`).then((response) => {
        feed.resource.data.user_membership.last_post_seen_at = response.data.last_post_seen_at;
    });
};

const scrollToBottom = () => {
    const windowY = feed.windowY == null ? document.documentElement.scrollHeight : feed.windowY;

    window.scrollTo({ top: windowY });
    window.scrollTo({ top: windowY });
};

const saveScrollPosition = () => {
    feed.windowY = window.scrollY;
};

provide('feed', feed);

const posts = ref(feed.posts);
const searchResult = ref(null);

const submitRequestReview = () => {
    requestReviewSubmitting.value = true;
    requestReview.value = false;

    axios.post(`/api/channels/${feed.resource.data.id}/request-review`).then(() => {
        feed.resource.data.status = 1;
        toast.success('Your request has been submitted');
        joinedChannels.fetch();
    }).catch((error) => {
        toast.error('An error occurred while submitting your request');
    }).finally(() => {
        requestReviewSubmitting.value = false;
    });
}

const getActiveFilter = () => {
    if (!route.query.filters) {
        return null;
    }

    const filters = JSON.parse(atob(route.query.filters));

    if (!filters.type) {
        return 'Filtered';
    }

    const names = {
        'pinned': 'Pinned',
        'mentions': 'Mentions',
        'saved': 'Saved',
    }

    return names[filters.type];
}

const getPosts = () => {
    if (!route.query.filters) {
        return feed.posts;
    }

    return feed.posts.newCollection();
}

onMounted(() => {
    feed.resource.fetchOnce().then(() => {
        if (!feed.resource.data.user_membership || feed.resource.data.user_membership.status !== 1) {
            // @TODO: redirect to info page
            return;
        }

        clearUnseenPosts();

        feed.posts.fetchOnce().then(() => {
            scrollToBottom();

            window.addEventListener('scroll', saveScrollPosition);
        });
    });
});

watch(() => route.query.filters, (filters) => {
    if (filters) {
        const value = {
            types: [],
        }

        filters = JSON.parse(atob(filters));

        if (filters.type === 'pinned') {
            value.pinned = true;
        }

        if (filters.type === 'mentions') {
            value.mentions = true;
        }

        if (filters.type === 'saved') {
            value.saved = true;
        }

        if (filters.posts) {
            value.types.push(1);
        }

        if (filters.questions) {
            value.types.push(2);
        }

        if (filters.polls) {
            value.types.push(3);
        }

        getPosts.value.setParams({
            filters: {
                channel: route.params.handle,
                ...value,
            },
        });

        getPosts.value.fetch();
    }
});

const postingDisabled = computed(() => {
    if (feed.resource.data.user_membership?.role_abilities['posts:bypass-restriction']) {
        return false;
    }

    return feed.resource.data.posting_disabled;
});

onUnmounted(() => {
    window.removeEventListener('scroll', saveScrollPosition);
});
</script>

<template>
    <Content class="bg-gray">
        <Header with-back-button>
            <div v-if="feed.resource.fetching" class="w-full md:w-1/2 animate-pulse">
                <div class="w-full h-4 rounded-full bg-white-foreground/20"></div>
                <div class="w-full h-2 rounded-full bg-white-foreground/20 mt-1"></div>
            </div>
            <template v-if="feed.resource.fetched">
                <Avatar :rounded-full="false" :src="feed.resource.data.image?.url || '/channel-placeholder.jpg'" />
                <button
                    v-if="feed.resource.data"
                    class="leading-none truncate flex flex-col justify-center min-w-0 grow bg-black/5 rounded-xl py-2 px-3"
                    @click.prevent="$router.push({ name: 'channels.info', params: { handle: feed.resource.data.handle } })"
                >
                    <h1 class="font-medium truncate text-sm leading-none">{{ feed.resource.data.name }}</h1>
                    <div class="text-xs text-white-foreground/60">
                        {{ feed.resource.data.approved_members_count }} members <span v-if="feed.resource.data.pinned_posts_count > 0">| {{ feed.resource.data.pinned_posts_count }} pinned</span>
                    </div>
                </button>

                <div class="ml-auto flex items-center">
                    <app-icon-button @click="$router.push({
                        name: 'channels.show.filter',
                        params: { handle: feed.resource.data.handle },
                        query: { search: '' }
                    })" type="button" icon="search" aria-label="Search" />
                    <Filter />
                </div>
            </template>
        </Header>

        <template v-if="feed.resource.fetched">
            <template v-if="feed.resource.data.status != 3">
                <div class="flex flex-col justify-center items-center h-[700px]">
                    <icon-clock-outline class="size-28 text-info" v-if="feed.resource.data.status == 1" />
                    <icon-exclamation-thick class="size-28 text-danger" v-else />
                    <div class="text-center mt-6 w-[80%]">
                        <h2 class="text-3xl font-semibold">
                            <template v-if="feed.resource.data.status == 1">Pending Review</template>
                            <template v-else-if="feed.resource.data.status == 4">Rejected</template>
                            <template v-else-if="feed.resource.data.status == 3">Banned</template>
                        </h2>
                        <div class="mt-4">
                            <template v-if="feed.resource.data.status == 1">
                                Your channel is currently under review by our moderation team. We'll notify you once the review is complete. This usually takes 24 - 48 business hours.
                            </template>
                            <template v-else-if="feed.resource.data.status == 4">
                                Your request to create this channel has been rejected. Please contact our moderation team if you have any questions about this decision.

                                <div class="mt-10">
                                    <app-button :disabled="requestReviewSubmitting" color="primary" @click="requestReview = true" full>
                                        Re-request a Review
                                    </app-button>
                                </div>

                                <app-dialog :open="requestReview" basic-width>
                                    <app-dialog-body>
                                        <app-dialog-title align-center>Re-request a Review?</app-dialog-title>
                                        <app-dialog-description align-center>
                                            Ensure that you have reviewed the rejection reasons sent to your email and made the necessary changes.
                                        </app-dialog-description>
                                    </app-dialog-body>
                                    <app-dialog-footer>
                                        <app-text-button @click="requestReview = false">No</app-text-button>
                                        <app-text-button color="primary" @click="submitRequestReview">Yes</app-text-button>
                                    </app-dialog-footer>
                                </app-dialog>
                            </template>
                            <template v-else-if="feed.resource.data.status == 2">
                                This channel has been banned by our moderation team for violating our community guidelines. Please contact our support team if you have any questions about this decision.
                            </template>
                        </div>
                    </div>
                </div>

            </template>
            <template v-else-if="feed.resource.data.user_membership?.status === $store.state.app.enums.member_status.APPROVED">
                <FeedPostList
                    class="mt-2"
                    :collection="feed.posts"
                    tracking-source="channel feed"
                    enable-tracking
                />

                <div v-if="postingDisabled" class="text-center text-sm text-white-foreground/60 pb-8">
                    Only admins can post in this channel
                </div>
                <div
                    v-if="feed.posts.fetched && feed.posts.data.length === 0 && !postingDisabled"
                    class="flex flex-col items-center justify-center mt-4 px-4 py-6 rounded-md">

                    <AppButton
                        color="primary"
                        class="mt-4"
                        capitalize
                        @click="$router.push({ name: 'posts.create', query: { channel: feed.resource.data.handle } })"
                    >
                        Start a conversation
                    </AppButton>
                </div>

                <FeedBottombar class="mt-4" />

                <button
                    v-if="!postingDisabled"
                    v-show="feed.posts.data.length > 0"
                    v-fixed="{ bottom: '14px', right: '14px', to: 'view-content' }"
                    aria-label="create a post"
                    type="button"
                    @click="$router.push({ name: 'posts.create', query: { channel: feed.resource.data.handle } })"
                    class="
                        flex justify-center items-center
                        bg-primary text-primary-foreground size-12 rounded-full z-[50]
                    "
                >
                    <IconPencilPlus class="size-7" />
                </button>
            </template>

            <template v-else>
                <div class="flex flex-col justify-center items-center h-[700px]">
                    <template v-if="feed.resource.data.user_membership?.status === store.state.app.enums.member_status.BANNED">
                        <icon-ban class="size-28" />
                        <div class="text-center mt-6 w-[80%]">
                            <h2 class="text-3xl font-semibold">
                                Banned
                            </h2>
                            <div class="mt-1">
                                You have been banned from <span class="font-semibold">{{ feed.resource.data.name }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <icon-account-lock class="size-28" />
                        <div class="text-center mt-6 w-[80%]">
                            <h2 class="text-3xl font-semibold">
                                Join Channel
                            </h2>
                            <div class="mt-1">
                                You are not yet a member of <span class="font-semibold">{{ feed.resource.data.name }}</span>. Join now to connect and stay updated with the latest activities in this channel.
                            </div>
                        </div>

                        <div class="mt-6">
                            <app-button color="primary-border" capitalize :as="RouterLink" :to="{ name: 'channels.info', params: { handle: feed.resource.data.handle } }">
                                Join Channel
                            </app-button>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </Content>
</template>

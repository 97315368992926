<script setup>
import { inject, nextTick, onMounted, provide, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useFeed } from '@/composables/channel';

import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import FeedPostList from '@/views/channels/_handle_/_helpers/FeedPostList.vue';
import FeedBottombar from '@/views/channels/_handle_/_helpers/FeedBottombar.vue';

const route = useRoute();
const router = useRouter();
const feed = useFeed(route.params.handle);

const posts = ref(null);
const searchInput = ref(null);

const getFilterTitle = () => {
    if (!route.query.filters) {
        return null;
    }

    const filters = JSON.parse(atob(route.query.filters));

    let types = filters.types.map(type => {
        if (type === 1) {
            return 'Posts';
        }

        if (type === 2) {
            return 'Questions';
        }

        if (type === 3) {
            return 'Polls';
        }

        return null;
    });
    types.push(filters.author ? 'Created by Me' : null);

    types = types.filter(Boolean).join(', ');

    if (filters.mentions) {
        return {
            title: 'Mentions',
            subtitle: types,
        };
    }

    if (filters.saved) {
        return {
            title: 'Saved',
            subtitle: types,
        };
    }

    return {
        title: 'Filtered',
        subtitle: types,
    };
}

const configure = () => {
    const filters = route.query.filters ? JSON.parse(atob(route.query.filters)) : {};
    const search = route.query.search || undefined;

    posts.value.setParams({
        query: search,
        filters: {
            ...filters,
            channel: route.params.handle,
        }
    });
}

let timeout = null;
let searchDefaultValue = null;

const search = (value) => {
    router.replace({
        query: {
            ...route.query,
            search: value,
        }
    }).then(() => {
        configure();
        posts.value.fetch().then(() => {
            window.scrollTo({
                top: document.scrollingElement.scrollHeight,
            })
        });
    });
}

const autoSearch = (e) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
        search(e.target.value);
    }, 500);
}

const openSearch = () => {
    router.push({
        query: { ...route.query, search: '' }
    }).then(() => {
        nextTick(() => {
            searchInput.value?.focus();
        });
    });
}

watch(() => route.query.search, () => {
    if (!('search' in route.query)) {
        configure();
        posts.value.reset();
        posts.value.fetch().then(() => {
            window.scrollTo({
                top: document.scrollingElement.scrollHeight,
            });
        });
    }
});

onMounted(() => {
    searchDefaultValue = route.query.search || undefined;

    feed.resource.fetchOnce().then(() => {
        posts.value = feed.posts.clone();

        configure();

        if ('search' in route.query) {
            searchInput.value.focus();
        }

        if ('search' in route.query && !route.query.filters && !route.query.search) {
            posts.value.fetchOnce().then(() => {
                window.scrollTo({
                    top: feed.windowY ? feed.windowY : document.scrollingElement.scrollHeight,
                })
            });
        } else {
            posts.value.reset();
            posts.value.fetch().then(() => {
                window.scrollTo({
                    top: document.scrollingElement.scrollHeight,
                })
            });
        }
    });
});

provide('feed', null);
</script>

<template>
    <Content class="bg-gray">
        <Header :with-back-button="!('search' in route.query)">
            <template v-if="'search' in route.query">
                <form class="flex items-center rounded-full bg-black/10 w-full" @submit.prevent="search">
                    <div class="shrink-0">
                        <app-icon-button type="button" @click="$router.back()" icon="arrow-back" />
                    </div>
                    <input
                        :value="route.query.search"
                        @input="autoSearch"
                        ref="searchInput"
                        class="pl-0 bg-transparent grow border-none focus:ring-0"
                        type="search"
                        placeholder="Search"
                    />
                    <!-- <div class="shrink-0">
                        <app-icon-button type="button" @click="searchInput.value = ''" icon="cancel" />
                    </div> -->
                </form>
            </template>
            <template v-else>
                <div class="flex flex-col justify-center">
                    <div class="text-base font-medium">
                        {{ getFilterTitle()?.title }}
                    </div>
                    <div v-if="getFilterTitle()?.subtitle" class="text-xs text-white-foreground/70">
                        {{ getFilterTitle()?.subtitle }}
                    </div>
                </div>
                <app-icon-button
                    class="ml-auto"
                    type="button"
                    @click="openSearch"
                    icon="search"
                />
            </template>
        </Header>

        <FeedPostList
            v-if="posts"
            class="mt-2"
            :collection="posts"
            tracking-source="channel feed"
            enable-tracking
        />

        <FeedBottombar />
    </Content>
</template>

<script setup>
import { useToast } from '@/plugins/toast';
import { markRaw, ref, getCurrentInstance } from 'vue';
import { useAjaxForm } from '@/utils/form';
import { useStore } from '@/store';
import { deepCopy } from '@/utils';

import Alert from '@/components/Modals/Alert.vue';
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import IconMoreVertical from '@/components/Icons/MoreVertical.vue';
import IconTrash from '@/components/Icons/TrashCanOutline.vue';

defineEmits(['deleted']);

const props = defineProps({
    comment: {
        required: true,
        type: Object,
    },
    userMembership: {
        required: false,
        type: Object,
    }
});

const instance = getCurrentInstance();
const toast = useToast();
const deleteForm = useAjaxForm();
const confirmDelete = ref(false);
const store = useStore();

deleteForm.onSuccess(() => {
    toast.publish({
        title: `${props.comment.is_reply ? 'Reply' : 'Comment'} deleted successfully.`,
        color: 'success',
    });
    confirmDelete.value = false;

    try {
        instance.emit('deleted');
    } catch (e) {
        console.error(e);
    }
});

const deleteComment = (alert) => {
    alert.setProcessing(true);
    deleteForm.delete(`/api/${props.comment.is_reply ? 'replies' : 'comments'}/${props.comment.id}`).finally(() => alert.setProcessing(false));
}

const actions = [
    {
        label: 'Delete',
        icon: markRaw(IconTrash),
        as: 'button',
        onSelect() {
            confirmDelete.value = true;
        },
        show: props.comment.acl.can_delete,
    },
    {
        label: 'Report',
        icon: 'icon-flag',
        as: 'button',
        onSelect() {
            store.reportContent(props.comment.is_reply ? 'reply' : 'comment', deepCopy(props.comment));
        },
        show: props.userMembership?.role_abilities['content:report'] === true
            && store.state.user
            && props.comment.user?.id != store.state.user.id,
    },
    {
        label: 'Block User',
        icon: 'icon-block',
        as: 'button',
        onSelect() {
            store.blockUser({
                user: props.comment.user,
                anonymous: props.comment.anonymously,
                source_id: props.comment.id,
                source_type: 'comment',
            });
        },
        show: props.comment.user?.id != store.state.user.id,
    },
];
</script>

<template>
    <DropdownMenu :items="actions" content-align="end" v-slot="{ visibleItems }">
        <DropdownMenuTrigger v-if="visibleItems.length > 0" class="text-white-foreground/60">
            <icon-more-vertical width="22" height="22" />
            <span class="sr-only">
                More options
            </span>
            <Alert
                danger
                v-model:open="confirmDelete"
                @confirmed="deleteComment"
                confirm-text="Delete"
                :title="`Delete ${props.comment.is_reply ? 'Reply' : 'Comment'}`"
                :description="`Are you sure you want to delete this ${props.comment.is_reply ? 'reply' : 'comment'}? This action is irreversible.`" />
        </DropdownMenuTrigger>
    </DropdownMenu>
</template>

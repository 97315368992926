import { reactive, inject } from "vue";

export function useStore() {
    const store = inject('store');

    if (!store) {
        throw new Error('useStore() is called without provider.');
    }

    return store;
}

export function createStore() {
    const state = reactive({
        user: null,
        deviceType: 'mobile',
        loading: false,
        newWorker: null,
        feedPromptOpened: false,
        mediaMuted: true,
    });

    return {
        state,
        blockUser({ user, anonymous, source_id, source_type, unblock }) {
            return new Promise((resolve) => {
                state.blockUser = {
                    unblock,
                    user,
                    anonymous,
                    source_id,
                    source_type,
                    done: resolve,
                };
            });
        },
        reportContent(itemType, content) {
            return new Promise((resolve) => {
                state.reportContent = {
                    itemType,
                    content,
                    done: resolve,
                };
            });
        },
        commit(prop, value) {
            state[prop] = value;
        },
        commitPush(prop, value) {
            state[prop].push(value);
        },
        saveUser() {
            localStorage.setItem('me', JSON.stringify(state.user));
        }
    }
}

<script setup>
import { inject } from "vue";

const props = defineProps({
    as: {
        type: String,
        default: 'div',
    },
    radio: {
        type: Boolean,
        default: false,
    },
    checkbox: {
        type: Boolean,
        default: false,
    },
    switch: {
        type: Boolean,
        default: false,
    },
});

const listItemProps = inject('app-list-item-props');
</script>

<template>
    <div class="shrink-0 ml-auto">
        <app-radio-button as="div" v-if="radio" :selected="listItemProps.selected" size="sm" />
        <app-checkbox v-else-if="checkbox" :selected="listItemProps.selected" size="sm" />
        <app-switch v-else-if="props.switch" :checked="listItemProps.selected" no-button />
        <slot v-else></slot>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useFeed } from "@/composables/channel";

import Content from "@/views/_partials/Content.vue";
import ConfigureNotification from "@/views/channels/_handle_/_helpers/ConfigureNotification.vue";
import Header from "@/views/_partials/header/Header.vue";
import HeaderTitle from "@/views/_partials/header/HeaderTitle.vue";

const route = useRoute();
const feed = useFeed(route.params.handle);

onMounted(() => {
    feed.resource.fetchOnce();
});
</script>

<template>
    <Content>
        <Header with-back-button>
            <div>
                <HeaderTitle class="leading-tight">Notifications</HeaderTitle>
            </div>
        </Header>

        <ConfigureNotification v-if="feed.resource.data?.user_membership" :member="feed.resource.data.user_membership" />
    </Content>
</template>

<script setup>
import { onMounted } from 'vue';
import { RouterLink } from 'vue-router';

import AppButton from '@/components/Button/Button.vue';
import ConversationList from '@/components/Conversation/List.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import IconMessageArrowRight from '@/components/Icons/MessageArrowRight.vue';
import IconMessagePlus from '@/components/Icons/MessagePlus.vue';
import IndexBase from '@/views/conversations/ListBase.vue';

const props = defineProps({
    baseProps: Object,
    as: {
        default: 'div',
        required: false,
    }
});

onMounted(() => {
    props.baseProps.acceptedConversations.fetchOnce();
    props.baseProps.conversationRequests.fetchOnce();
});
</script>

<template>
    <component :is="as">
        <Header :fix-to="$store.state.deviceType === 'mobile' ? 'conversation-content' : 'conversation-sidebar'">
            <HeaderTitle>
                Chats
            </HeaderTitle>

            <div class="ml-auto">
                <RouterLink v-if="$store.state.deviceType !== 'mobile'" class="block" :to="{ name: 'conversations.start' }">
                    <IconMessagePlus class="size-7" />
                </RouterLink>
            </div>
        </Header>
        <RouterLink v-if="baseProps.conversationRequests.fetched && baseProps.conversationRequests.data.length > 0" class="block border-b border-white-foreground/10" :to="{ name: 'conversations.requests' }">
            <div class="flex gap-2 p-4 items-center">
                <IconMessageArrowRight class="size-7" />
                <div>
                    <div class="font-medium">Chat requests ({{ baseProps.conversationRequests.meta.total }})</div>
                    <div class="text-sm text-white-foreground/70">
                    Requests from people not yet in your network
                    </div>
                </div>
            </div>
        </RouterLink>

        <ConversationList :route-replace="$route.name !== 'conversations.index' && $store.state.deviceType !== 'mobile'" :conversations="baseProps.acceptedConversations" />
        <div class="mt-[30%] flex flex-col items-center justify-center" v-if="(baseProps.acceptedConversations.fetched && baseProps.acceptedConversations.data.length === 0) && (baseProps.conversationRequests.fetched && baseProps.conversationRequests.data.length === 0)">
            <img src="@images/no-chats.svg" class="w-[300px] h-[300px]" />
            <div class="text-center mx-6">
                <div class="text-3xl font-bold mt-10">
                    No new conversations
                </div>
                <div class="mt-2">
                    Connect and start a conversation with someone new today! Expand your network and make meaningful connections.
                </div>
            </div>
            <div class="w-[250px]">
                <AppButton :as="RouterLink" class="mt-4" color="primary" :to="{ name: 'conversations.start' }">
                    Start Chat
                </AppButton>
            </div>
        </div>
    </component>
</template>

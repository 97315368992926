export function elPositionFix ({ el, style = {}, noFiller = false }) {
    if (el.children.length !== 1) {
        throw new Error('elPositionFix: The element must have exactly one direct child.');
    }

    const child = el.children[0];
    const filler = document.createElement('div');
    filler.style.width = '100%';
    filler.style.height = window.getComputedStyle(child).height;

    const setStyle = (style) => {
        for (const key in style) {
            child.style[key] = style[key];
        }
    }

    const fix = (el, style) => {
        const computedStyle = window.getComputedStyle(el);
        const width = computedStyle.width;

        child.style.position = 'fixed';
        child.style.width = width;

        setStyle(style);
    }

    fix(el, style);

    if (!noFiller) {
        el.appendChild(filler);
    }

    const resizeObserver = new ResizeObserver(() => {
        fix(el, style);
    });

    const childObserver = new ResizeObserver(() => {
        filler.style.height = window.getComputedStyle(child).height;
    });

    resizeObserver.observe(el);

    if (!noFiller) {
        childObserver.observe(child);
    }

    return {
        setStyle,
        disconnect() {
            resizeObserver.disconnect();
            childObserver.disconnect();
        }
    };
}

import button from '@/components/Button/index';
import checkbox from '@/components/Checkbox/index';
import dialog from '@/components/Dialog/index';
import list from '@/components/List/index';
import radioButton from '@/components/RadioButton/index';
import icons from '@/icons';
import appSwitch from '@/components/Switch/index';
import dividers from '@/components/Dividers/index';
import bottomSheets from '@/components/BottomSheets/index';
import head from '@/components/Head/index';

export default {
    ...button,
    ...checkbox,
    ...dialog,
    ...list,
    ...radioButton,
    ...icons,
    ...appSwitch,
    ...dividers,
    ...bottomSheets,
    ...head,
}

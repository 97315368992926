import { onMounted, onUnmounted, reactive } from "vue"

export const useWindowScroll = () => {
    let lastTop = 0;
    let observer = null;
    let timeout = null;

    const state = reactive({
        top: 0,
        scrolling: false,
        scrollableY: 0,
        bottom: 0,
        direction: null,
    });

    const setValues = () => {
        state.top = window.scrollY;
        state.bottom = document.body.scrollHeight - ( window.innerHeight + window.scrollY );
        state.scrollableY = document.body.scrollHeight - window.innerHeight;
    }

    const scrollListener = () => {
        clearTimeout(timeout);
        state.scrolling = true;

        if (window.scrollY < lastTop) {
            state.direction = 'up';
        } else {
            state.direction = 'down';
        }

        lastTop = window.scrollY;
        setValues();

        timeout = setTimeout(() => {
            state.scrolling = false;
        }, 250)
    };

    onMounted(() => {
        setValues();
        observer = new ResizeObserver(() => {
            setValues();
        });
        observer.observe(document.body);
        window.addEventListener('scroll', scrollListener);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', scrollListener);
        clearTimeout(timeout);
        observer?.disconnect();
    });

    return {
        get top () {
            return state.top;
        },
        get bottom () {
            return state.bottom;
        },
        get scrolling () {
            return state.scrolling;
        },
        get direction () {
            return state.direction;
        },
    };
}

<script setup>
import { useToast } from '@/plugins/toast';

const props = defineProps({
    url: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: false,
    },
});

const toast = useToast();
const share = () => {
    navigator.share({
        title: props.title,
        text: props.text,
        url: props.url,
    }).catch((error) => console.error(error));
};
</script>

<template>
    <app-icon-button
        v-if="$store.state.deviceType === 'mobile'"
        type="button"
        @click="share"
        aria-label="Share"
        icon="share-variant"
        button-style="outlined"
    />
</template>

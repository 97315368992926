import { useRoute, useRouter } from 'vue-router';

export const useModalRouter = () => {
    const router = useRouter();
    const route = useRoute();

    return {
        open(key, params) {
            return router.push({
                query: {
                    ...route.query,
                    modal: key,
                    modalParams: typeof params === 'object' ? btoa(JSON.stringify(params)) : params,
                }
            });
        },
        openCreatePost(params) {
            return this.open('create-post', params);
        }
    }
}

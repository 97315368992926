<script setup>
import { onMounted, watch } from "vue";

const props = defineProps({
    value: {
        type: String,
    },
});

onMounted(() => {
    if (props.value) {
        document.title = props.value;
    }
});

watch(() => props.value, (value) => {
    if (value) {
        document.title = value;
    }
});
</script>

<template>
</template>

<script setup>
import {computed, reactive, onMounted, watch, ref } from 'vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';

import { useToast } from '@/plugins/toast';
import { usePage } from '@/composables/page';
import { useStore } from '@/store';
import { useFeed } from '@/composables/channel';

import Avatar from '@/components/Avatar.vue';
import AppButton from '@/components/Button/Button.vue';
import Badge from '@/components/Badge.vue';
import Card from '@/components/Card.vue';
import Content from '@/views/_partials/Content.vue';
import Guidelines from '@/components/Guidelines.vue';
import IconDateRange from '@/components/Icons/DateRange.vue';
import IconUserAdd from '@/components/Icons/UserAdd.vue';
import IconUserCheck from '@/components/Icons/UserCheck.vue';
import IconSearch from '@/components/Icons/Search.vue';
import IconSettingsOutline from '@/components/Icons/SettingsOutline.vue';
import IconPrivate from '@/components/Icons/Private.vue';
import IconPublic from '@/components/Icons/Public.vue';
import IconShare from '@/components/Icons/Share.vue';
import IconSettings from '@/components/Icons/Settings.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import Input from '@/components/Form/Input.vue';
import LineClamp from '@/components/LineClamp.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import Member from '@/views/channels/_handle_/Member.vue';
import NativeShare from '@/components/NativeShare.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';
import TabsContent from '@/components/Tabs/TabsContent.vue';
import TabsList from '@/components/Tabs/TabsList.vue';
import TabsRoot from '@/components/Tabs/TabsRoot.vue';
import TabsTrigger from '@/components/Tabs/TabsTrigger.vue';
import UserCard from '@/components/User/UserCard.vue';
import PillGroup from '@/components/Pills/PillGroup.vue';
import Pill from '@/components/Pills/Pill.vue';
import RoleBadge from '@/components/Member/RoleBadge.vue';
import { collectionV2 } from '@/composables/resource';

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const historyState = reactive({
    activeTab: 'about',
});
const feed = useFeed(route.params.handle);

const members = collectionV2({
    url: `/api/channels/${route.params.handle}/members`,
});

const leaveDescription = computed(() => {
    if (!feed.resource.fetched) {
        return '';
    }

    let text = `You are already a member of <span class=font-semibold>'${feed.resource.data.name}'</span>. Do you want to leave?`;

    if (feed.resource.data.requires_approval_to_join) {
        text += `<br><br><i>Leaving means you'll need approval to rejoin.</i>`;
    }

    return text;
});

const joinConfirmText = computed(() => {
    if (!feed.resource.fetched) {
        return '';
    }

    if (feed.resource.data.requires_approval_to_join) {
        return 'Request to Join';
    }

    return 'Join';
});

const joinApprovalDescription = computed(() => {
    if (!feed.resource.fetched) {
        return '';
    }

    let text = 'Are you sure you want to join <span class=font-semibold>' + `${feed.resource.data.name}` + '</span>?';

    if (feed.resource.data.requires_approval_to_join) {
        text += '<br>Requesting to join will require approval.';
    }

    return text;
});

const memberActionSuccessful = (leaveChannel = false) => {
    const message = leaveChannel
        ? `You have successfully left ${feed.resource.data.name}.`
        : `You have successfully ${feed.resource.data.requires_approval_to_join ? 'requested to join' : 'joined'} ${feed.resource.data.name}.`;
    toast.publish({
        title: message,
        color: 'success',
    });

    if (!leaveChannel) {
        router.replace({
            name: 'channels.show',
            params: { handle: feed.resource.data.handle }
        })
    }
};

const channelJoined = (res) => {
    feed.resource.fetch();
    const approved = res.data.status == store.state.app.enums.member_status.APPROVED;
    if (approved) {
        toast.success('You have successfully joined the feed.resource.');
        members.fetch();
        feed.posts.fetch();
    } else {
        toast.success('You have successfully requested to join the feed.resource.');
    }
}

const channelLeft = (res) => {
    feed.resource.data.user_membership = res.data;
    toast.success('You have successfully left the feed.resource.');
}

const memberViews = [
    { key: 'active', label: 'Active', params: { filters: { status: 1 } } },
    { key: 'pending', label: 'Pending', params: { filters: { status: 0 } } },
    { key: 'banned', label: 'Banned', params: { filters: { status: 3 } } },
];

const activeMemberView = ref('active');

const filterMembers = (key) => {
    if (key === activeMemberView.value) {
        return;
    }

    activeMemberView.value = key;
    members.setParams(memberViews.find(view => view.key === key).params);
    members.refresh();
}

const memberQuery = ref(route.query.memberQuery || '');
const searchFormActive = ref(memberQuery.value !== '');

const runSearch = () => {
    members.setParams({
        query: memberQuery.value || undefined,
    });

    members.fetch();
};

const setMemberQuery = (value) => {
    router.replace({
        query: {
            ...route.query,
            memberQuery: value || undefined,
        }
    })
}

const memberUpdated = () => {
    feed.resource.fetch();
    members.refresh();
}

const shareUrl = computed(() => {
    if (typeof window === 'undefined' || !feed.resource.fetched) {
        return '';
    }

    return `${window.location.origin}/channels/${feed.resource.data.handle}/info`;
});

onMounted(() => {
    feed.resource.fetchOnce().then(() => {
        members.setUrl(`/api/channels/${feed.resource.data.id}/members`);

        if (!feed.resource.data.user_membership || feed.resource.data.user_membership.status !== 1) {
            return;
        }

        members.fetch();
    });
})
</script>

<template>
    <Content class="bg-gray">
        <Header with-back-button>
            <HeaderTitle>Channel</HeaderTitle>

            <div class="grow"></div>

            <app-icon-button
                v-if="feed.resource.fetched && feed.resource.data.user_membership?.role === 1"
                type="button"
                icon="settings"
                :disabled="feed.resource.data.status == 1"
                @click="$router.push({
                    name: 'channels.edit',
                    params: { handle: $route.params.handle }
                })"
            />
        </Header>

        <template v-if="feed.resource.fetched">
            <div class="px-4 py-4 bg-white text-white-foreground">
                <div class="flex items-start leading-none">
                    <Avatar as-link :rounded-full="false" size="xl" :src="feed.resource.data.image?.url || '/channel-placeholder.jpg'" :image="feed.resource.data.image" />
                    <div class="ml-2 leading-snug">
                        <div class="text-lg font-medium">
                            {{ feed.resource.data.name }}
                        </div>
                        <ul class="flex flex-row gap-x-4 gap-y-1 mt-1 flex-wrap">
                            <li class="flex gap-1 text-sm">
                                <span class="font-medium">{{ feed.resource.data.approved_members_count_abbrv }}</span>
                                <span class="opacity-70">Members</span>
                            </li>
                            <li class="flex gap-1 text-sm">
                                <span class="font-medium">{{ feed.resource.data.posts_count_abbrv }}</span>
                                <span class="opacity-70">Posts</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="mt-4 flex items-center gap-2">
                    <template v-if="feed.resource.data.status == 3">
                        <AppButton
                            v-if="feed.resource.data.user_membership?.status === $store.state.app.enums.member_status.APPROVED"
                            rounded-full compact capitalize color="primary-border"
                            :action="`/api/members/${feed.resource.data.user_membership.id}/leave`"
                            @action:success="channelLeft"
                            :bind-alert="{
                                title: 'Leave Channel',
                                description: leaveDescription,
                                confirmText: 'Leave Channel',
                                cancelText: 'No',
                                danger: true,
                            }"
                        >
                            <IconUserCheck height="18" width="18" />
                            <span>Joined</span>
                        </AppButton>
                        <AppButton v-else-if="feed.resource.data.user_membership?.status === $store.state.app.enums.member_status.PENDING"
                            rounded-full compact capitalize color="warning-border"
                            :action="`/api/members/${feed.resource.data.user_membership.id}/leave`"
                            @action:success="channelLeft"
                            :bind-alert="{
                                title: 'Cancel Join Request',
                                description: 'Are you sure you want to cancel your join request?',
                                confirmText: 'Cancel Request',
                                cancelText: 'No',
                                danger: true,
                            }"
                        >
                            <IconUserCheck height="18" width="18" />
                            <span>Pending</span>
                        </AppButton>
                        <AppButton v-else-if="!feed.resource.data.user_membership || feed.resource.data.user_membership.status === $store.state.app.enums.member_status.LEFT"
                            rounded-full compact capitalize color="primary"
                            :action="!feed.resource.data.user_membership ? `/api/channels/${feed.resource.data.id}/members` : `/api/members/${feed.resource.data.user_membership.id}/re-join`"
                            @action:success="channelJoined"
                            :bind-alert="{
                                title: 'Join Channel',
                                description: joinApprovalDescription,
                                confirmText: joinConfirmText,
                            }"
                        >
                            <IconUserAdd height="18" width="18" />
                            <span>Join</span>
                        </AppButton>
                        <AppButton v-else-if="feed.resource.data.user_membership?.status === $store.state.app.enums.member_status.DECLINED"
                            rounded-full compact capitalize color="danger-border"
                            :action="`/api/members/${feed.resource.data.user_membership.id}/re-request`"
                            @action:success="channelJoined"
                            :bind-alert="{
                                title: 'Request to Join',
                                description: 'Are you sure you want to request to join this channel? Your request was denied previously',
                                confirmText: 'Yes',
                            }"
                        >
                            <IconUserAdd height="18" width="18" />
                            <span>Declined</span>
                        </AppButton>
                    </template>

                    <div class="flex">
                        <NativeShare :title="`Join ${feed.resource.data.name} Channel on Corperland!`" class="" :url="shareUrl" />

                        <app-icon-button v-if="feed.resource.data.user_membership" @click="$router.push({
                            name: 'channels.notification-settings',
                            params: { handle: $route.params.handle }
                        })" icon="notification-settings" button-style="outlined" />
                    </div>
                </div>
            </div>

            <!-- TABS -->
            <TabsRoot :model-value="$route.query.tab === 'members' ? 'members' : 'about'" @update:model-value="(value) => {
                if (value === 'about') {
                    $router.replace();
                } else {
                    $router.replace({
                        query: {
                            ...$route.query,
                            tab: 'members',
                        }
                    });
                }
            }">
                <div class="pt-4 bg-white text-white-foreground">
                    <TabsList aria-label="Navigate Channel">
                        <TabsTrigger value="about">
                            About
                        </TabsTrigger>
                        <TabsTrigger value="members">
                            Members
                        </TabsTrigger>
                    </TabsList>
                </div>
                <div class="pt-3 md:px-4">
                    <TabsContent value="about">
                        <Card>
                            <h2 class="text-base font-semibold">Channel Info</h2>
                            <LineClamp lines="3">
                                <div class="text-base" v-html="feed.resource.data.description"></div>
                            </LineClamp>
                            <div class="mt-4 px-4">
                                <div class="flex gap-4">
                                    <div class="text-white-foreground/70">
                                        <IconPublic v-if="feed.resource.data.is_public" class="size-6" />
                                        <IconPrivate v-else class="size-6" />
                                    </div>
                                    <div class="leading-none">
                                        <div class="font-medium">
                                            {{ feed.resource.data.is_public ? 'Public' : 'Private' }} Channel
                                        </div>
                                        <div class="text-sm text-white-foreground/70">
                                            {{ feed.resource.data.is_public ? 'Anyone can join this channel' : 'Membership requires approval' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex gap-4 mt-3">
                                    <div class="text-white-foreground/70">
                                        <IconDateRange height="24" width="24" />
                                    </div>
                                    <div class="leading-snug">
                                        <div class="font-medium">
                                            Created on {{ new Date(feed.resource.data.created_at).toLocaleDateString() }} by <RouterLink class="text-primary" :to="{
                                                name: 'profiles.show',
                                                params: { username: feed.resource.data.user.profile.username }
                                            }">{{ feed.resource.data.user.name }}</RouterLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card class="mt-4">
                            <h2 class="text-base font-semibold">Community Guidelines</h2>
                            <Guidelines />
                        </Card>
                    </TabsContent>

                    <TabsContent value="members" class="px-4 bg-white py-4">
                        <template v-if="members.fetched">
                            <div class="flex pb-4">
                                <PillGroup v-if="feed.resource.data.user_membership.role != 3">
                                    <Pill as="button" type="button" @click="filterMembers(view.key)" v-for="view in memberViews" :key="view.key" :data-active="activeMemberView === view.key">
                                        {{ view.label }} <Badge class="ml-2" v-if="view.key === 'pending'" :count="feed.resource.data.pending_members_count" />
                                    </Pill>
                                </PillGroup>
                                <button @click="searchFormActive = !searchFormActive" type="button" aria-label="Search members" class="block ml-auto">
                                    <IconSearch class="size-6" />
                                </button>
                            </div>

                            <form v-if="searchFormActive" class=" pb-4">
                                <Input type="search" v-model="memberQuery" :debounce="200" placeholder="Search members" @finished-typing="runSearch" @typing="setMemberQuery" />
                            </form>
                        </template>

                        <Skeleton :collection="members" />
                        <ul v-if="members.data.length > 0" class="flex flex-col gap-4">
                            <li v-for="member in members.data" :key="member.id">
                                <RouterLink :to="{
                                    query: {
                                        ...$route.query,
                                        member: member.id,
                                    }
                                }" class="border bg-white text-white-foreground px-4 py-3 rounded-md flex flex-row items-center gap-2 border-white-foreground/10">
                                    <div>
                                        <Avatar size="xl" :src="member.user.profile.avatar?.url" :fallback-from="member.user.name" />
                                    </div>
                                    <div class="flex flex-col leading-none min-w-0">
                                        <div class="flex flex-row">
                                            <p class="truncate">
                                                <span class="font-semibold">{{ member.user.name }}</span><span class="opacity-70">&nbsp;@{{ member.user.profile.username }}</span>
                                            </p>
                                            <RoleBadge class="ml-2" :member="member" only-admins />
                                        </div>
                                        <p class="mt-1">{{ member.user.profile.type_label }}</p>
                                    </div>
                                </RouterLink>
                            </li>
                        </ul>
                        <InfiniteScrollObserver :collection="members" />
                    </TabsContent>
                </div>
            </TabsRoot>
        </template>

        <Member v-if="$route.query.member" :channel="feed.resource" @member:updated="memberUpdated" />
    </Content>
</template>

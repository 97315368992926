<script setup>
import { computed, inject, provide } from "vue";

const props = defineProps({
    as: {
        type: String,
        default: 'li',
    },
    alignCenter: {
        type: Boolean,
        default: false,
    },
    selected: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    bordered: {
        type: Boolean,
        default: false,
    },
    targetAs: {
        type: String,
        default: 'div',
    },
    targetBind: {
        type: Object,
    },
});

const listProps = inject('app-list-props');

provide('app-list-item-props', computed(() => props));
</script>

<template>
    <component :is="as" class="border-white-foreground/20" :class="{
        '[&:not(:last-child)]:border-b': bordered,
    }">
        <component :is="targetAs" :disabled="disabled"
            type="button"
            v-bind="targetBind"
            class="
                block w-full text-left my-auto hover:bg-white-foreground/10 focus:bg-white-foreground/20 active:bg-white-foreground/20
                disabled:opacity-[.38] disabled:cursor-not-allowed
                transition-colors duration-300
            "
            :class="{
                'min-h-[56px]': !listProps.dense && !listProps.compact,
                'min-h-[48px]': listProps.compact,
                'min-h-[40px]': listProps.dense,
            }"
        >
            <div class="flex flex-row gap-2 px-4 py-2" :class="{
                'items-center': alignCenter,
            }">
                <slot></slot>
            </div>
        </component>
    </component>
</template>

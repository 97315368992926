<script setup>

import { elPositionFix } from '@/utils/fixed';
import { nextTick, onMounted, onUnmounted, ref } from 'vue';

const expanded = defineModel('expanded', {
    type: Boolean,
    default: false,
});

defineProps({
    disableDrag: {
        type: Boolean,
        default: false,
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
});

const content = ref(null);
const el = ref(null);
let fix = null;

const contentWidth = ref(null);

onMounted(() => {
    fix = elPositionFix({ el: el.value, style: { bottom: 0, zIndex: 1000 } });

    nextTick(() => {
        contentWidth.value = content.value?.clientWidth;
    });
});

onUnmounted(() => {
    fix?.disconnect();
});
</script>

<template>
    <div ref="el">
        <div>
            <div
                :class="{
                    'h-screen md:h-auto md:max-h-[50vh] md:rounded-t-2xl': expanded,
                    'max-h-[50vh]': !expanded,
                    'w-full': fullWidth,
                    'rounded-tr-2xl': fullWidth && !expanded,
                    'rounded-tl-2xl': !expanded,
                    'w-fit': !fullWidth,
                }"
                :style="{ width: contentWidth && !fullWidth ? contentWidth + 'px' : undefined }"
                class="shadow-[0_4px_14px_0] shadow-black/25 ml-auto transition-[width] duration-300">
                <div class=""></div>
                <div ref="content" class="h-full bg-white text-white-foreground rounded-[inherit]">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

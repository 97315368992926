<script setup>
import { DialogRoot, DialogPortal, DialogOverlay, DialogContent } from 'radix-vue';

const open = defineModel('open', {
    type: Boolean,
    default: false,
});
</script>

<template>
    <div>
        <DialogRoot :open="open" @update:open="open = $event">
            <DialogPortal>
                <DialogOverlay
                    class="
                        bg-black/10 data-[state=open]:animate-overlayShow data-[state=closed]:animate-overlayHide
                        fixed inset-0 z-50
                    " />
                <DialogContent
                    @openAutoFocus="(e) => e.preventDefault()"
                    class="
                        fixed z-[100] max-md:bottom-0 max-md:data-[state=open]:animate-in max-md:data-[state=closed]:animate-out
                        max-md:data-[state=open]:slide-in-from-bottom-[100%] max-md:data-[state=closed]:slide-out-to-bottom-[100%]
                        md:top-[50%] md:left-[50%]
                        max-h-[80vh] md:max-h-[85vh] max-md:w-full md:max-w-[90vw] max-w-[450px] md:w-[450px]
                        md:translate-x-[-50%] md:translate-y-[-50%]
                        focus:outline-none bg-white text-white-foreground
                        rounded-t-3xl
                    "
                    >
                    <slot></slot>
                </DialogContent>
            </DialogPortal>
        </DialogRoot>
    </div>
</template>

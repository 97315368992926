import { inject, reactive } from "vue";

const key = Symbol('dialogs');

const dialogs = (navigation) => {
    const state = reactive({
        dialogs: [],
    });

    return {
        /**
         * Register a dialog to be closed on back navigation.
         * @param {Function} dialog - The dialog to be closed.
         */
        register(dialog) {
            state.dialogs.push(dialog);
        },
        unregister(dialog) {
            state.dialogs = state.dialogs.filter(d => d !== dialog);
        },
        onNavigation() {
            if (navigation.direction !== 'back') {
                return true;
            }

            if (state.dialogs.length === 0) {
                return true;
            }

            const dialog = state.dialogs.pop();
            dialog();

            return false;
        }
    }
}

export function useDialogs() {
    const dialogs = inject(key);

    if (!dialogs) {
        throw new Error('useDialogs() is called without provider.');
    }

    return dialogs;
}

export function createDialogs({ navigation }) {
    return {
        install(app) {
            const plugin = dialogs(navigation);
            app.config.globalProperties.$dialogs = plugin;
            app.provide(key, plugin);
        }
    }
}

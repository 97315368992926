<script setup>
import { onMounted, nextTick, ref, onActivated, getCurrentInstance, onDeactivated, reactive, onUnmounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import axios from '@/axios';
import echo from '@/plugins/echo';
import { getFriendlyTime } from '@/utils';
import { useAjaxForm } from '@/utils/form';
import { collectionV2, useResource } from '@/composables/resource';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';
import { registerFileUploader } from '@/views/files/_helpers/index';
import { elPositionFix } from '@/utils/fixed';

import AppButton from '@/components/Button/Button.vue';
import Avatar from '@/components/Avatar.vue';
import ConversationLayout from '@/views/_partials/ConversationLayout.vue';
import ConversationSidebar from '@/views/_partials/ConversationSidebar.vue';
import ConversationContent from '@/views/_partials/ConversationContent.vue';
import Content from '@/views/_partials/Content.vue';
import ThumbGallery from '@/components/File/ThumbGallery.vue';
import MessageContent from '@/components/Renderer/Content.vue';
import DeleteMessages from '@/views/conversations/_id_/DeleteMessages.vue';
import IconImages from '@/components/Icons/Images.vue';
import FilePicker from '@/components/Form/FilePicker.vue';
import FilePickerDisplay from '@/components/Form/FilePickerDisplay.vue';
import Fixed from '@/components/Fixed.vue';
import IconArrowBack from '@/components/Icons/ArrowBack.vue';
import IconCopy from '@/components/Icons/Copy.vue';
import IconSubmit from '@/components/Icons/Submit.vue';
import IconTrashCanOutline from '@/components/Icons/TrashCanOutline.vue';
import IndexContent from '@/views/conversations/_helpers/IndexContent.vue';
import IconCheckAll from '@/components/Icons/CheckAll.vue';
import IconLoader from '@/components/Icons/Loader.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import RequestsContent from '@/views/conversations/_helpers/RequestsContent.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import TextBox from '@/components/TextBox.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';
import Uploader from '@/views/files/Uploader.vue';

defineEmits(['conversation:accepted', 'conversation:blocked']);

defineOptions({
    name: 'conversations.show',
});

defineProps({
    baseProps: Object,
});

registerFileUploader({
    queryMode: true,
});

const instance = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const store = useStore();
const toast = useToast();
const messagesContainer = ref(null);
let conversationChannel = null;
const componentIsActive = ref(false);
const picker = ref();
const bottombar = ref();
let bottombarFix = null;

const conversation = useResource(`/api/conversations/${route.params.id}`);
const messages = collectionV2({
    url: `/api/conversations/${route.params.id}/messages`,
});

const orderedMessages = computed(() => {
    return messages.data.sort((a, b) => {
        return a.created_at_timestamp - b.created_at_timestamp;
    });
});

const form = useAjaxForm({
    content: '',
    type: 1,
    files: [],
});

const validData = computed(() => {
    return form.content || (form.files && form.files.length > 0);
})

const acceptForm = useAjaxForm();

const updateConversation = (data) => {
    conversation.data = data;
}

const getEventMessage = (message) => {
    const you = message.member.id == route.params.id;

    if (message.content.type == 'conversation.accepted') {
        return you ? 'You accepted the request to chat' : `${message.member.user.name} accepted your request to chat`;
    }

    console.error('Unknown event message type', message.content.type);

    return '';
}

acceptForm.onSuccess((response) => {
    updateConversation(response.data.data);
    messages.data.push(response.data.data.last_message);
    instance.emit('conversation:accepted', { conversation: conversation.data });
});

const declineForm = useAjaxForm();

declineForm.onSuccess(() => {
    instance.emit('conversation:declined', { conversation: conversation.data });
    router.replace({ name: 'conversations.index' });
});

const scrollToBottom = () => {
    nextTick(() => {
        if (messagesContainer.value) {
            messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
        }
    });
};

const sendMessage = () => {
    form.post(`/api/conversations/${route.params.id}/messages`, {
        onSuccess: (response) => {
            const message = response.data.data;
            messages.data.push(message);
            conversation.data.last_message = message;

            instance.emit('message:created', { conversation: conversation.data });

            scrollToBottom();
        },
    });

    form.reset();
    form.files = [];
    picker.value.clear();
    scrollToBottom();
}

const messagesViewed = () => {
    if (messages.data && messages.data.length > 0) {
        const unread_message = messages.data.find((message) => message.read_at == null);

        if (unread_message) {
            axios.post(`/api/conversations/${route.params.id}/messages-viewed`)
                .then((response) => {
                    messages.data.forEach((message) => {
                        message.read_at = response.data.read_at;
                    });

                    conversation.data.last_message.read_at = response.data.read_at;
                });
        }
    }
}

const messagesDeletedForMe = (messageIds) => {
    let hardDelete = false;

    messageIds.forEach((messageId) => {
        const message = messages.data.find((message) => message.id == messageId);

        if (!message) {
            return;
        }

        if (message.deleted) {
            hardDelete = true;
        }
    });

    messageIds.forEach((messageId) => {
        const message = messages.data.find((message) => message.id == messageId);

        if (!message) {
            return;
        }

        if (hardDelete) {
            messages.data.splice(messages.data.indexOf(message), 1);
        } else {
            message.deleted = true;
        }
    });
};

const onMessagesDeleted = ({ response, messages }) => {
    messagesDeletedForMe(messages);

    cancelAction();

    conversation.data.last_message = response.data.data.last_message;
};

const sidebarContent = {
    'requests': RequestsContent,
    'default': IndexContent,
}

const action = reactive({
    active: false,
    messages: [],
});

let touchTimer = null;

const handleTouchStart = (message) => {
    if (action.active) {
        return;
    }

    touchTimer = setTimeout(() => {
        action.active = true;
        action.messages.push(message);
    }, 1000);
};

const handleTouchEnd = () => {
    if (touchTimer) {
        clearTimeout(touchTimer);
        touchTimer = null;
    }
};

const handleClick = (message) => {
    if (!action.active) {
        return;
    }

    if (action.messages.includes(message)) {
        action.messages.splice(action.messages.indexOf(message), 1);
    } else {
        action.messages.push(message);
    }

    if (action.messages.length == 0) {
        action.active = false;
        action.messages = [];
    }
}

const cancelAction = () => {
    action.active = false;
    action.messages = [];
}

const onMessageCreated = (event) => {
    axios.get(`/api/conversations/${conversation.data.id}/messages`, {
        params: {
            event_message_id: event.message_id,
        }
    }).then((response) => {
        if (response.data.data.length > 0) {
            messages.data.push(response.data.data[0]);

            if (componentIsActive.value) {
                scrollToBottom();
                messagesViewed();
            }
        }
    });
}

let oldScrollHeight;
let oldScrollTop;

const saveScrollPosition = () => {
    oldScrollHeight = messagesContainer.value.scrollHeight;
    oldScrollTop = messagesContainer.value.scrollTop;
};

const onFetchingOldMessages = () => {
    saveScrollPosition();

    messagesContainer.value.addEventListener('scroll', saveScrollPosition);
};

const onOldMessagesFetched = async () => {
    messagesContainer.value.removeEventListener('scroll', saveScrollPosition);

    await nextTick();

    const newScrollHeight = messagesContainer.value.scrollHeight;
    messagesContainer.value.scrollTop = oldScrollTop + (newScrollHeight - oldScrollHeight);
};

const clearAction = () => {
    action.messages = [];
    action.active = false;
}

const copyMessages = () => {
    const messages = action.messages.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
    }).map((memberMessage) => {
        return memberMessage.message.content;
    });

    const text = messages.join('\n\n');

    navigator.clipboard.writeText(text);

    toast.success(`${messages.length > 1 ? 'Messages' : 'Message'} copied`)

    clearAction();
}

const actionContainsDeleted = computed(() => {
    return action.messages.filter((message) => message.deleted).length > 0;
});

const shouldShowCopyAction = computed(() => {
    return navigator?.clipboard && !actionContainsDeleted.value;
});

onMounted(() => {
    bottombarFix = elPositionFix({
        el: bottombar.value,
        style: {
            bottom: '0px',
            zIndex: 50,
        }
    });

    if (route.query.action == 'delete-messages' && action.messages.length === 0) {
        router.replace({
            name: 'conversations.show',
            params: { id: route.params.id },
        });
    }

    componentIsActive.value = true;

    conversation.fetch({
        onsuccess: (data) => {
            conversationChannel = echo.private(`conversations.${data.data.conversation.id}`);

            conversationChannel.listen('.message:created', onMessageCreated);
        }
    });
    messages.fetch().then(() => {
        scrollToBottom();
        messagesViewed();
    });
});

onUnmounted(() => {
    if (bottombarFix) {
        bottombarFix.disconnect();
    }

    if (conversationChannel) {
        conversationChannel.stopListening('.message:created', onMessageCreated);
    }
});

onActivated(() => {
    componentIsActive.value = true;
    scrollToBottom();
    messagesViewed();
});

onDeactivated(() => {
    componentIsActive.value = false;
});

const textMode = computed(() => {
    return form.content || (form.files && form.files.length > 0);
})
</script>

<template>
    <ConversationLayout>
        <ConversationSidebar v-if="$store.state.deviceType !== 'mobile'">
            <component :is="sidebarContent[$route.query.referer || 'default']" :baseProps="baseProps" />
        </ConversationSidebar>

        <ConversationContent>
            <Header :back-button-only-mobile="!action.active" fix-to="conversation-content">
                <template v-if="action.active">
                    <button @click="cancelAction" type="button">
                        <IconArrowBack class="size-7" />
                    </button>
                    <div class="text-xl mr-auto">
                        {{ action.messages.length }}
                    </div>
                    <div class="flex gap-4">
                        <RouterLink :to="{
                            name: 'conversations.show',
                            params: { id: route.params.id },
                            query: {
                                action: 'delete-messages',
                            },
                        }">
                            <span class="sr-only">Delete selected messages</span>
                            <IconTrashCanOutline class="size-7" />
                        </RouterLink>
                        <button v-if="shouldShowCopyAction" aria-label="copy message" @click="copyMessages" type="button">
                            <IconCopy class="size-7" />
                        </button>
                    </div>
                </template>
                <template v-else>
                    <RouterLink v-if="conversation.filled()" :to="{
                        name: 'profiles.show',
                        params: { username: conversation.data.conversation.with_me ? $store.state.user.profile.username : conversation.data.other_members[0].user.profile.username },
                    }">
                        <Avatar :src="conversation.data.conversation.with_me ? $store.state.user.profile.avatar?.url : conversation.data.other_members[0].user.profile.avatar?.url" size="lg" :fallback-from="conversation.data.conversation.with_me ? $store.state.user.name : conversation.data.other_members[0].user.name" />
                    </RouterLink>
                    <RouterLink v-if="conversation.filled()" :to="{
                        name: 'profiles.show',
                        params: { username: conversation.data.conversation.with_me ? $store.state.user.profile.username : conversation.data.other_members[0].user.profile.username },
                    }" class="flex flex-col">
                        <span class="font-semibold text-lg leading-none">{{ conversation.data.conversation.with_me ? $store.state.user.name + ' (You)' : conversation.data.other_members[0].user.name }}</span>
                        <span class="opacity-70 leading-none">@{{ conversation.data.conversation.with_me ? $store.state.user.profile.username : conversation.data.other_members[0].user.profile.username }}</span>
                    </RouterLink>
                </template>
            </Header>
            <div class="overflow-y-hidden w-full h-[calc(100vh-61px)] flex flex-col">
                <div ref="messagesContainer" class="bg-gray grow min-w-0 overflow-y-auto">
                    <InfiniteScrollObserver :collection="messages" @fetching="onFetchingOldMessages" @fetched="onOldMessagesFetched">
                        <div class="fixed top-[70px] w-full py-2 flex items-center justify-center">
                            <span class="sr-only">Loading more</span>
                            <IconLoader class="size-10 opacity-70" />
                        </div>
                    </InfiniteScrollObserver>

                    <Skeleton :collection="messages" />
                    <ul v-if="messages.fetched && messages.data.length > 0" class="flex flex-col gap-2 relative mt-4">
                        <li v-for="message in orderedMessages" :key="message.id" @contextmenu.prevent>
                            <div class="flex items-center justify-center" v-if="message.message?.type == 2">
                                <div class="text-xs py-2 px-4 bg-white text-white-foreground/70 rounded-full max-w-[70%] text-center">
                                    {{ getEventMessage(message.message) }}
                                </div>
                            </div>
                            <div v-else :class="{
                                'justify-end': message.from_me,
                                'justify-start': !message.from_me,
                                'bg-primary bg-opacity-15': action.active && action.messages.includes(message),
                            }"
                            class="w-full flex cursor-pointer py-2 px-4 transition-colors duration-300"
                            @click="handleClick(message)"
                            >
                                <div @touchstart="handleTouchStart(message)" @touchend="handleTouchEnd" @touchcancel="handleTouchCancel" :class="{
                                    'rounded-br-none bg-primary text-primary-foreground border-primary-foreground/20': message.from_me,
                                    'rounded-bl-none bg-white text-white-foreground border-white-foreground/20': !message.from_me,
                                    'text-opacity-70 italic': message.deleted || !message.message,
                                    'max-w-[300px]': !message.message?.files || message.message.files.length < 1,
                                    'w-[300px]': message.message.files && message.message.files.length > 0,
                                }" class="flex flex-col min-w-[40px] min-h-[40px] rounded-3xl py-3 px-4 select-none">
                                    <div v-if="message.deleted">
                                        You deleted this message
                                    </div>
                                    <div v-else-if="!message.message">
                                        Message deleted
                                    </div>
                                    <div v-else>
                                        <ThumbGallery class="mb-2" v-if="message.message.files && message.message.files.length > 0" :files="message.message.files" :gallery-id="'message-' + message.id" />
                                        <MessageContent :mentions="message.message.mentions" :bg="message.from_me ? 'primary' : 'white'" :content="message.message.content" />
                                    </div>
                                    <div class="flex items-center opacity-70 ml-auto gap-1">
                                        <div class="text-xs">{{ getFriendlyTime(message.created_at) }}</div>
                                        <IconCheckAll v-if="message.from_me" class="size-4" />
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                <div class="w-full" ref="bottombar">
                    <div class="w-full">
                        <div
                            v-if="conversation.data && !conversation.data.conversation_accepted"
                            class="bg-white text-white-foreground px-4 py-6">
                            <div class="text-center text-sm select-none md:max-w-[90%] mx-auto">
                                This person is outside your network. Accept their chat request to start messaging.
                            </div>

                            <div class="flex justify-between gap-4 mt-4">
                                <div class="w-full">
                                    <AppButton @click="declineForm.post(`/api/conversations/${route.params.id}/decline`)" color="danger-border" type="button" full :loading="declineForm.processing">
                                        <span>Block</span>
                                    </AppButton>
                                </div>
                                <div class="w-full">
                                    <AppButton @click="acceptForm.post(`/api/conversations/${route.params.id}/accept`)" color="primary-border" type="button" full :loading="acceptForm.processing">
                                        <span>Accept</span>
                                    </AppButton>
                                </div>
                            </div>
                        </div>
                        <form
                            v-else-if="conversation.data"
                            @submit.prevent="sendMessage"
                            class="w-full bg-white text-white-foreground py-4 px-2 flex gap-4"
                            :class="{
                                'flex-col': textMode,
                                'items-center': !textMode,
                            }"
                        >
                            <div class="min-w-0 self-stretch w-full flex flex-col gap-2"
                            >
                                <TextBox v-model="form.content" :rows="1" placeholder="Send a message" />
                                <FilePickerDisplay :picker="picker" />
                            </div>

                            <div class="shrink-0 flex">

                                <FilePicker
                                    class="block ml-auto"
                                    ref="picker"
                                    v-model="form.files"
                                    aria-label="add images or videos"
                                    type="button"
                                    multiple
                                    is-private
                                >
                                    <IconImages class="size-6" />
                                </FilePicker>
                                <button
                                    v-show="textMode"
                                    type="submit"
                                    :disabled="form.processing || !validData"
                                    class="
                                        text-primary p-1
                                        rounded-full flex items-center justify-center
                                        disabled:opacity-60 disabled:cursor-not-allowed ml-2
                                        ">
                                    <IconSubmit class="size-6" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ConversationContent>

        <DeleteMessages v-if="$route.query.action === 'delete-messages'" :conversation-member-id="route.params.id" :messages="action.messages" @messages:deleted="onMessagesDeleted" />
        <Uploader v-if="$route.query.modal === 'file-uploader'" />
    </ConversationLayout>
</template>

<script setup>
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useJoinedChannels } from '@/composables/channel';
import { elPositionFix } from '@/utils/fixed';
import { useStore } from '@/store';

import AppButton from '@/components/Button/Button.vue';

const emit = defineEmits(['channelSelected']);

const props = defineProps({
    channelId: {
        type: String,
        required: false,
    }
});

const route = useRoute();
const store = useStore();

const selectedChannel = ref(null);
const channels = useJoinedChannels();
const bottomBar = ref(null);
let fixedBottomBar = null;

const nextStep = () => {
    try {
        emit('channelSelected', selectedChannel.value);
    } catch (error) {
        console.log(error);
    }
}

onMounted(() => {
    channels.fetchOnce().then(() => {
        if (props.channelId) {
            selectedChannel.value = channels.data.find(channel => channel.id === props.channelId);
        }
    });

    fixedBottomBar = elPositionFix({
        el: bottomBar.value,
        style: {
            bottom: '0px',
            zIndex: 50,
        }
    });
});

onUnmounted(() => {
    if (fixedBottomBar) {
        fixedBottomBar.disconnect();
    }
});
</script>

<template>
    <div>
        <div class="p-4">
            <h1 class="font-medium text-lg">
                Select One Channel
            </h1>
            <p class="text-sm leading-tight">
                You can post a message or ask a question in channels that you are a member of.
            </p>
            <div v-if="channels.data.length">
                <ul class="flex flex-col mt-4">
                    <li v-for="channel in channels.data" :key="channel.id">
                        <button type="button"
                            @click.prevent="selectedChannel = channel"
                            :data-active="selectedChannel?.id === channel.id"
                            :disabled="channel.posting_disabled && !channel.user_membership?.role_abilities['posts:bypass-restriction']"
                            class="p-3 flex flex-col w-full relative data-[active=true]:bg-primary/5 rounded-md disabled:opacity-50 disabled:cursor-not-allowed">
                            <span class="font-medium">{{ channel.name}}</span>
                            <span class="opacity-70 text-sm">#{{ channel.handle }}</span>
                            <div class="
                                absolute right-2 h-6 w-6 rounded-full border
                                border-white-foreground border-opacity-20
                                top-1/3 flex items-center justify-center
                            "
                            >
                                <div :data-active="selectedChannel?.id === channel.id" class="h-3 w-3 data-[active=true]:bg-primary rounded-full"></div>
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div ref="bottomBar">
            <div class="bg-white px-4 py-4">
                <AppButton
                    class="w-full"
                    @click.prevent="nextStep"
                    type="button"
                    :disabled="!selectedChannel"
                    color="primary"
                >
                    Select
                </AppButton>
            </div>
        </div>
    </div>
</template>

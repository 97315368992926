<script setup>
import { computed, onMounted, ref, watch } from 'vue';

import axios from '@/axios';
import { useStore } from '@/store';
import { useAjaxForm } from '@/utils/form';

import Error from '@/components/Form/Error.vue';
import Textarea from '@/components/Form/Textarea.vue';
import { useEmitter } from '@/plugins/emitter';

const store = useStore();
const form = useAjaxForm();
const emitter = useEmitter();

const open = ref(false);
const data = computed(() => store.state.reportContent);
const selectedReason = ref(null);
const selectedReasonDetails = ref('');

const formIsValid = computed(() => {
    if (!selectedReason.value) {
        return false;
    }

    if (selectedReason.value === 'other') {
        return selectedReasonDetails.value.length > 0 && selectedReasonDetails.value.length <= 400;
    }

    return true;
});

const closeDialog = () => {
    selectedReason.value = null;
    open.value = false;
    setTimeout(() => {
        store.commit('reportContent', null);
    }, 300);
}

const reportContent = () => {
    form.post(`/api/content-reports/${data.value.itemType}/${data.value.content.id}`, {
        data: {
            reason: selectedReason.value,
            details: selectedReasonDetails.value,
        },
        onSuccess: () => {
            closeDialog();
            emitter.emit(`.${data.value.itemType}:reported`, data.value.content);

            if (data.value.content.user) {
                store.blockUser({
                    user: data.value.content.user,
                });
            }
        },
    });
}

watch(() => store.state.reportContent, (value) => {
    open.value = Boolean(value);
}, { immediate: true });

onMounted(() => {
    if (!store.state.contentReportReasons) {
        axios.get('/api/content-report-reasons').then((response) => {
            store.commit('contentReportReasons', response.data.data);
        });
    }
})
</script>

<template>
    <div>
        <app-dialog :open="open" full-screen @update:open="closeDialog">
            <template v-if="data">
                <app-dialog-header>
                    <app-icon-button icon="arrow-back" @click="closeDialog" />
                    <app-dialog-title>Report Content</app-dialog-title>
                    <div class="grow"></div>
                    <app-text-button :disabled="!formIsValid" @click="reportContent">Report</app-text-button>
                </app-dialog-header>
                <app-dialog-body padding-bottom :padding-top="false">
                    <app-list class="-mx-6">
                        <app-list-item
                            v-for="reason in store.state.contentReportReasons"
                            :key="reason.key"
                            :selected="selectedReason === reason.key"
                            @click="selectedReason = reason.key"
                            align-center
                        >
                            <app-list-content :headline="reason.label" :supporting-text="reason.description" />
                            <app-list-trailing radio />
                        </app-list-item>
                    </app-list>

                    <Textarea
                        v-if="selectedReason === 'other'"
                        v-model="selectedReasonDetails"
                        placeholder="Please explain why you are reporting this content"
                    />
                    <Error
                        v-if="selectedReason === 'other' && selectedReasonDetails.length > 400"
                        :value="`Maximum of 400 characters`" />
                </app-dialog-body>
            </template>
        </app-dialog>
    </div>
</template>

<script setup>
import { DialogRoot, DialogContent, DialogPortal, DialogOverlay, DialogTitle, VisuallyHidden } from 'radix-vue';
import { markRaw, ref, onMounted } from 'vue';
import { RouterLink } from 'vue-router';
import { useStore } from '@/store';

import { useLogout } from '@/composables/actions';
import { usePwaInstall } from '@/plugins/pwa-install';

import Avatar from '@/components/Avatar.vue';
import IconListStatus from '@/components/Icons/ListStatus.vue';
import IconAboutCircleOutline from '@/components/Icons/AboutCircleOutline.vue';
import IconLoader from '@/components/Icons/Loader.vue';
import IconLogout from '@/components/Icons/Logout.vue';
import IconMoonAndStars from '@/components/Icons/MoonAndStars.vue';
import IconShieldLockOutline from '@/components/Icons/ShieldLockOutline.vue';
import IconSettingsOutline from '@/components/Icons/SettingsOutline.vue';
import IconUsersGroupOutline from '@/components/Icons/UsersGroup.vue';
import Modal from '@/components/Modals/Modal.vue';
import ProfileLink from '@/components/Link/ProfileLink.vue';
import IconShare from '@/components/Icons/Share.vue';
import { useToast } from '@/plugins/toast';

defineProps({
    open: Boolean,
});

defineEmits(['update:open']);

const logout = useLogout();

const darkModeSettingsOpen = ref(false);

const pwa = usePwaInstall();
const toast = useToast();
const store = useStore();

const links = ref([
    {
        to: { name: 'connections.index' },
        icon: markRaw(IconUsersGroupOutline),
        label: 'Connections',
    },
    {
        to: { name: 'settings.index' },
        icon: markRaw(IconSettingsOutline),
        label: 'Settings',
    },
    {
        to: { name: 'guidelines' },
        icon: markRaw(IconListStatus),
        label: 'Community Guidelines',
    },
    {
        to: { name: 'about' },
        icon: markRaw(IconAboutCircleOutline),
        label: 'About Corperland',
    },
    {
        to: { name: 'agreements.privacy-policy' },
        icon: markRaw(IconShieldLockOutline),
        label: 'Privacy Policy',
    },
]);

const shareApp = () => {
    let url = "https://play.google.com/store/apps/details?id=ng.corperland.twa";
    if (pwa.isAppleMobilePlatform) {
        url = "https://corperland.ng";
    }

    if (navigator.share) {
        navigator.share({
            title: "Join Me on Corperland!",
            text: "Hey! I'm on Corperland connecting with other NYSC members, sharing stories, and exploring cool channels. Come check it out and let's connect there!",
            url: url,
        })
            .then()
            .catch((error) => console.error('Error sharing', error));
    } else {
        toast.publish({
            title: 'Web Share API is not supported in your browser.',
            color: 'danger',
        });
    }
};

const handleShare = () => {
    if (store.state.user.is_ambassador) {
        const referralCode = store.state.user.referral_code || '';
        const ambassadorReferralLink = referralCode ? `${import.meta.env.VITE_APP_URL}/amb/${referralCode}` : import.meta.env.VITE_APP_URL;

        navigator.clipboard.writeText(ambassadorReferralLink).then(() => {
            toast.info('Referral link copied.');
        }).catch((error) => {
            toast.error('Failed to copy link.');
            console.error('Failed to copy link:', error);
        });
    } else {
        shareApp();
    }
};
</script>

<template>
    <div>
        <DialogRoot :open="open" @update:open="$emit('update:open')">
            <DialogPortal>
                <DialogOverlay
                    class="bg-black/20 data-[state=open]:animate-overlayShow data-[state=closed]:animate-overlayHide fixed inset-0 z-50" />
                <DialogContent class="
                        fixed focus:outline-none z-50 top-0 left-0 h-full bg-white text-white-foreground
                        data-[state=open]:animate-in data-[state=open]:slide-in-from-left-[280px]
                        data-[state=closed]:animate-out data-[state=closed]:slide-out-to-left-[280px]
                        w-[280px]
                    ">
                    <VisuallyHidden>
                        <DialogTitle>Menu</DialogTitle>
                    </VisuallyHidden>

                    <div class="flex flex-col h-full">
                        <div class="p-4 border-b border-white-foreground/10 relative">
                            <ProfileLink class="block w-fit" :profile="$store.state.user.profile">
                                <Avatar size="2xl" :src="$store.state.user.profile.avatar?.url"
                                    :fallback-from="$store.state.user.name" />
                            </ProfileLink>

                            <ProfileLink class="block mt-2 w-fit" :profile="$store.state.user.profile">
                                <p class="text-lg font-semibold leading-tight">{{ $store.state.user.name }}</p>
                                <p class="opacity-70">@{{ $store.state.user.profile.username }}</p>
                                <span class="text-primary text-sm font-medium">View Profile</span>
                            </ProfileLink>

                            <button class="absolute right-3 top-4 rounded-full" type="button"
                                aria-label="toggle dark mode" @click="() => {
            $emit('update:open', false);
            darkModeSettingsOpen = true;
        }">
                                <IconMoonAndStars class="text-white-foreground/70" height="28" width="28" />
                            </button>
                        </div>

                        <ul class="grow flex flex-col mt-24">
                            <li v-for="link in links" :key="link.to">
                                <RouterLink :to="link.to" class="flex gap-2 items-center w-full p-4">
                                    <component :is="link.icon" class="size-7" />
                                    <span class="text-lg">{{ link.label }}</span>
                                </RouterLink>
                            </li>
                        </ul>

                        <div class="mt-auto border-t border-white-foreground/10"
                            v-if="$store.state.deviceType === 'mobile'">
                            <button class="flex gap-2 items-center w-full p-4" @click="handleShare">
                                <IconShare class="size-6" />
                                <span>{{ $store.state.user.is_ambassador ? 'Referral Link' : 'Share/Invite Friends' }}</span>
                            </button>
                        </div>

                        <div class="mt-auto border-t border-white-foreground/10">
                            <button class="flex gap-2 items-center w-full p-4" type="button" @click="logout.execute()">
                                <IconLogout class="size-6" />
                                <span>Logout</span>
                                <div class="ml-auto" v-if="logout.processing">
                                    <IconLoader class="size-6" />
                                </div>
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </DialogPortal>
        </DialogRoot>

        <Modal :open="darkModeSettingsOpen" @update:open="(e) => { darkModeSettingsOpen = e; }" id="dark-mode-settings">
            <DialogTitle class="font-bold text-xl py-3 -mx-4 px-4 border-b border-white-foreground/10">
                Dark mode <button type="button" @click="$router.push('/notifications')">
                    click me
                </button>
            </DialogTitle>
            <div class="-mx-4 flex flex-col">
                <button type="button" class="text-left px-4 py-3 flex" @click="$settings.setDarkModeOff()">
                    <span class="text-lg">Off</span>
                    <div class="ml-auto size-6 border border-primary rounded-full flex items-center justify-center">
                        <div :data-active="$settings.darkModePreference === 'off'"
                            class="w-4/6 h-4/6 data-[active=true]:bg-primary rounded-full"></div>
                    </div>
                </button>
                <button type="button" class="text-left px-4 py-3 flex" @click="$settings.setDarkModeOn()">
                    <span class="text-lg">On</span>
                    <div class="ml-auto size-6 border border-primary rounded-full flex items-center justify-center">
                        <div :data-active="$settings.darkModePreference === 'on'"
                            class="w-4/6 h-4/6 data-[active=true]:bg-primary rounded-full"></div>
                    </div>
                </button>
                <button type="button" class="text-left px-4 py-3 flex" @click="$settings.setDarkModeDevice()">
                    <span class="text-lg">Use device settings</span>
                    <div class="ml-auto size-6 border border-primary rounded-full flex items-center justify-center">
                        <div :data-active="$settings.darkModePreference === 'device'"
                            class="w-4/6 h-4/6 data-[active=true]:bg-primary rounded-full"></div>
                    </div>
                </button>
            </div>
        </Modal>
    </div>
</template>

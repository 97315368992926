<script setup>
import { ref, onMounted, computed } from 'vue';

import { useSetThemeColor } from '@/composables/actions';
import { usePwaInstall } from '@/plugins/pwa-install';
import { useStore } from '@/store';

import Fixed from '@/components/Fixed.vue';
import Logo from '@/components/Icons/Logo.vue';
import Screenshots from '@/components/Auth/Screenshots.vue';
import UpdateFound from '@/components/UpdateFound.vue';

useSetThemeColor();

const pwa = usePwaInstall();
const store = useStore();

const isInstallAvailable = ref(pwa.isInstallAvailable);

const shouldShowInstallBanner = computed(() => {
    return store.state.deviceType === 'mobile' && pwa && isInstallAvailable && !pwa.isUnderStandaloneMode;
});

const showScreenshots = computed(() => {
    return typeof window !== 'undefined' && store.state.deviceType !== 'mobile';
});

const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=ng.corperland.twa', '_blank');
};

onMounted(() => {
    setTimeout(() => {
        isInstallAvailable.value = pwa.isInstallAvailable;
    }, 1200);
});
</script>

<template>
    <div data-theme-ref class="bg-gray text-gray-foreground">
        <Fixed :top="0">
            <UpdateFound />
        </Fixed>
        <div class="min-h-screen w-screen flex flex-col justify-center items-center" :class="{
            'pb-[60px]': shouldShowInstallBanner
        }">
            <div class="flex justify-center items-center w-full">
                <Screenshots v-if="showScreenshots" />

                <div class="w-full md:w-[350px] lg:w-[400px] grow md:grow-0 md:border border-gray-foreground/20 md:rounded-xl py-8 flex flex-col items-center justify-center px-4 shrink-0">
                    <RouterLink class="block mt-4" :to="{ name: 'feed' }">
                        <Logo />
                    </RouterLink>
                    <slot />
                </div>
            </div>
            <div class="w-full text-center text-sm text-white-foreground/70 py-10">
                <RouterLink :to="{ name: 'about' }">About Us</RouterLink> |
                <RouterLink :to="{ name: 'agreements.privacy-policy' }">Privacy Policy</RouterLink> |
                <RouterLink :to="{ name: 'agreements.eula' }">EULA</RouterLink> |
                <RouterLink :to="{ name: 'information-hub.index' }">Information Hub</RouterLink>
            </div>
            <div v-if="shouldShowInstallBanner" class="flex justify-between items-center w-full bg-white text-white-foreground px-4 h-[60px] fixed bottom-0">
                <p class="text-sm">Download the app for the best experience</p>
                <button @click="pwa.isAppleMobilePlatform ? pwa.showDialog() : openPlayStore()" class="block font-semibold bg-primary text-primary-foreground rounded text-sm px-3 py-1">
                    Open
                </button>
            </div>
        </div>
    </div>
</template>

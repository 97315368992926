<script setup>
import { computed, provide } from "vue";

const props = defineProps({
    dense: {
        type: Boolean,
        default: false,
    },
    compact: {
        type: Boolean,
        default: false,
    },
});

provide('app-list-props', computed(() => props));
</script>

<template>
    <ul class="flex flex-col">
        <slot></slot>
    </ul>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useToast } from '@/plugins/toast';

import { useFeed } from '@/composables/channel';

import Content from '@/views/_partials/Content.vue';
import Form from '@/components/Channel/Form.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';

const toast = useToast();
const route = useRoute();

const formComponent = ref();
const feed = useFeed(route.params.handle);

const updated = (response) => {
    toast.success('Channel updated successfully');
    feed.resource.fill(response);
}

const submit = () => {
    formComponent.value.form.patch(`/api/channels/${feed.resource.data.id}`, { onSuccess: (response) => updated(response.data) });
}

onMounted(() => {
    feed.resource.fetchOnce();
});
</script>

<template>
    <Content>
        <Header with-back-button>
            <HeaderTitle>Edit Channel</HeaderTitle>
            <div class="grow"></div>
            <app-text-button v-if="formComponent" @click="submit" :disabled="formComponent.canSubmit">Save</app-text-button>
        </Header>

        <div v-if="feed.resource.fetched && feed.resource.data.status != 1" class="mt-4 px-4">
            <Form ref="formComponent" :channel="feed.resource.data" @submit.prevent="submit" />
        </div>
    </Content>
</template>

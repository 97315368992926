import { isset } from '@/utils';

const fix = function (el, config) {
    const top = typeof config.top === 'number' ? config.top + 'px' : config.top;
    const bottom = typeof config.bottom === 'number' ? config.bottom + 'px' : config.bottom;
    const left = typeof config.left === 'number' ? config.left + 'px' : config.left;
    const right = typeof config.right === 'number' ? config.right + 'px' : config.right;
    const width = typeof config.width === 'number' ? config.width + 'px' : config.width;

    const target = config.to ? document.getElementById(config.to) : null;

    if (!target) {
        if (config.fixed === false) {
            el.style.removeProperty('position');
        } else {
            el.style.position = 'fixed';
        }

        if (isset(top)) {
            el.style.top = top;
        } else {
            el.style.removeProperty('top');
        }

        if (isset(bottom)) {
            el.style.bottom = bottom;
        } else {
            el.style.removeProperty('bottom');
        }

        if (isset(left)) {
            el.style.left = left;
        } else {
            el.style.removeProperty('left');
        }

        if (isset(right)) {
            el.style.right = right;
        } else {
            el.style.removeProperty('right');
        }

        if (isset(width)) {
            el.style.width = width;
        } else {
            el.style.removeProperty('width');
        }

        return;
    }

    const targetRect = target.getBoundingClientRect();
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    if (isset(top)) {
        const targetTop = Math.min(window.innerHeight, Math.max(0, targetRect.top));
        let topValue;

        if (top.endsWith('%')) {
            const percent = Number(top.replace('%', '')) / 100;
            topValue = targetTop + (percent * Math.min(window.innerHeight, targetRect.height));
        } else {
            topValue = targetTop + Number(top.replace('px', ''));
        }

        el.style.top = topValue + 'px';
    } else {
        el.style.removeProperty('top');
    }

    if (isset(right)) {
        const targetRight = window.innerWidth - Math.min(window.innerWidth, targetRect.right) - scrollbarWidth;
        let rightValue;

        if (right.endsWith('%')) {
            const percent = Number(right.replace('%', '')) / 100;
            rightValue = targetRight + (percent * Math.min(window.innerWidth, targetRect.width));
        } else {
            rightValue = targetRight + Number(right.replace('px', ''));
        }

        el.style.right = rightValue + 'px';
    } else {
        el.style.removeProperty('right');
    }


    if (isset(bottom)) {
        const targetBottom = window.innerHeight - Math.min(window.innerHeight, targetRect.bottom);
        let bottomValue;

        if (bottom.endsWith('%')) {
            const percent = Number(bottom.replace('%', '')) / 100;
            bottomValue = targetBottom + (percent * Math.min(window.innerHeight, targetRect.height));
        } else {
            bottomValue = targetBottom + Number(bottom.replace('px', ''));
        }

        el.style.bottom = bottomValue + 'px';
    } else {
        el.style.removeProperty('bottom');
    }

    if (isset(left)) {
        const targetLeft = targetRect.left;
        let leftValue;

        if (left.endsWith('%')) {
            const percent = Number(left.replace('%', '')) / 100;
            leftValue = targetLeft + (percent * Math.min(window.innerWidth, targetRect.width));
        } else {
            leftValue = targetLeft + Number(left.replace('px', ''));
        }

        el.style.left = leftValue + 'px';
    } else {
        el.style.removeProperty('left');
    }

    if (isset(width)) {
        const targetWidth = Math.min(window.innerWidth, target.clientWidth);
        let widthValue;

        if (width.endsWith('%')) {
            const percent = Number(width.replace('%', '')) / 100;
            widthValue = percent * targetWidth;
        } else {
            widthValue = width.replace('px', '');
        }

        el.style.width = widthValue + 'px';
    } else {
        el.style.removeProperty('width');
    }

    el.style.position = 'fixed';
}

export const vFixed = {
    mounted(el, binding) {
        fix(el, binding.value);

        const observer = new ResizeObserver(function () {
            fix(el, binding.value);
        });

        if (binding.value.to && document.getElementById(binding.value.to)) {
            observer.observe(document.getElementById(binding.value.to));
        }

        window.addEventListener('scroll', () => fix(el, binding.value));
    },
    updated(el, binding) {
        fix(el, binding.value);
        window.removeEventListener('scroll', () => fix(el, binding.value));
        window.addEventListener('scroll', () => fix(el, binding.value));
    },
    unmounted(el, binding) {
        window.removeEventListener('scroll', () => fix(el, binding.value));
    },
}

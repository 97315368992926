<script setup>
import { useAjaxForm } from "@/utils/form";
import { computed, onBeforeUnmount, reactive, ref } from "vue";
import dayjs from "dayjs";

import { getFriendlyTime } from "@/utils";
import { onBeforeRouteLeave } from "vue-router";

const props = defineProps({
    member: Object,
    disableAutoSave: Boolean,
});

const ajax = useAjaxForm({});

const showMuteOptions = ref(false);

const muteOptions = [
    {
        label: '8 hours',
        value: '8h',
    },
    {
        label: '1 day',
        value: '1d',
    },
    {
        label: '1 week',
        value: '1w',
    },
    {
        label: '1 month',
        value: '1M',
    },
    {
        label: 'Until I unmute',
        value: null,
    },
];

const contentOptions = [
    {
        label: 'Frequent',
        value: 'enabled',
        description: 'Receive frequent updates of new contents.',
    },
    {
        label: 'Summary',
        value: 'summary',
        description: 'Daily summary of all new contents posted in this channel.',
    },
    {
        label: 'Turn off',
        value: 'disabled',
        description: 'You will still be notified when admins use @everyone mentions.',
    },
];

const selectedMuteOption = ref(null);

const mute = () => {
    state.data.notifications_muted = true;
    props.member.notifications_muted = true;

    if (selectedMuteOption.value) {
        const val = selectedMuteOption.value[0];
        const unit = selectedMuteOption.value[1];
        const rawtime = dayjs().add(val, unit).toISOString();

        state.data.notifications_muted_until = rawtime;
        props.member.notifications_muted_until = rawtime;
    }

    showMuteOptions.value = false;

    autoSave();
}

const state = {
    data: reactive({}),
    save() {
        ajax.patch(`/api/members/${props.member.id}`, {
            data: this.data,
        });
    },
};

let autoSaveTimeout = null;

const autoSave = (timeout = 5000) => {
    if (props.disableAutoSave) {
        return;
    }

    clearTimeout(autoSaveTimeout);

    autoSaveTimeout = setTimeout(() => {
        state.save();
    }, timeout);
}

const mutedSupportingText = computed(() => {
    if (props.member.notifications_muted_until) {
        return `Muted until ${getFriendlyTime(props.member.notifications_muted_until)}`;
    }

    if (props.member.notifications_muted) {
        return `All notifications from this channel are muted.`;
    }

    return "Mute all notifications from this channel.";
});

const toggleMute = () => {
    if (props.member.notifications_muted) {
        props.member.notifications_muted = false;
        props.member.notifications_muted_until = null;
        state.data.notifications_muted = false;
        state.data.notifications_muted_until = null;
        autoSave();
    } else {
        selectedMuteOption.value = '8h';
        showMuteOptions.value = true;
    }
}

const toggleMentions = () => {
    const value = props.member.mention_notifications === 'enabled' ? 'disabled' : 'enabled';

    props.member.mention_notifications = value;
    state.data.mention_notifications = value;

    autoSave();
}

const selectContentOption = (value) => {
    props.member.post_notifications = value;
    state.data.post_notifications = value;

    autoSave();
}

onBeforeUnmount(() => {
    autoSave(100);
});

onBeforeRouteLeave(() => {
    autoSave(100);
});

defineExpose({
    state,
});
</script>

<template>
    <div>
        <app-list>
            <app-list-item @click="toggleMute" :selected="member.notifications_muted">
                <app-list-content
                    headline="Mute Channel"
                    :supporting-text="mutedSupportingText"
                />
                <app-list-trailing switch />
            </app-list-item>
        </app-list>

        <app-modal-bottom-sheet v-model:open="showMuteOptions">
            <h1 class="leading-tight px-4 mt-8 text-white-foreground">How long do you want to mute this channel?</h1>
            <app-list class="mt-4" compact>
                <app-list-item
                    v-for="option in muteOptions"
                    :key="option.value"
                    :selected="selectedMuteOption === option.value"
                    @click="selectedMuteOption = option.value"
                >
                    <app-list-content :headline="option.label" />
                    <app-list-trailing radio />
                </app-list-item>
            </app-list>

            <div class="px-4 pb-4 flex justify-end gap-4 mt-10">
                <app-button @click="showMuteOptions = false" capitalize>Cancel</app-button>
                <app-button @click="mute" color="primary" capitalize>Mute</app-button>
            </div>
        </app-modal-bottom-sheet>

        <template v-if="!member.notifications_muted">
            <app-hr />
            <h2 class="text-sm font-medium leading-tight px-4 mt-4 text-primary">
                Content Notifications
            </h2>
            <app-list>
                <app-list-item
                    v-for="option in contentOptions"
                    :key="option.value"
                    align-center
                    @click="selectContentOption(option.value)"
                    :selected="member.post_notifications === option.value"
                >
                    <app-list-content :headline="option.label" :supporting-text="option.description" />
                    <app-list-trailing radio />
                </app-list-item>
            </app-list>

            <app-hr />
            <h2 class="text-sm font-medium leading-tight px-4 mt-4 text-primary">
                Other Notifications
            </h2>
            <app-list>
                <app-list-item align-center @click="toggleMentions" :selected="member.mention_notifications === 'enabled'">
                    <app-list-content headline="Mentions" />
                    <app-list-trailing switch />
                </app-list-item>
            </app-list>
        </template>
    </div>
</template>

<script setup>
import beforeCamp from '@images/before-camp.svg';
import duringCamp from '@images/during-camp.svg';
import afterCamp from '@images/after-camp.svg';

import { collectionV2 } from '@/composables/resource';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import { onBeforeMount } from 'vue';

const categories = collectionV2({
    url: '/api/information-hub-categories',
})

onBeforeMount(() => {
    categories.fetch(null, null, true);
});

const getImage = (slug) => {
    if (slug === 'before-camp') {
        return beforeCamp;
    }
    if (slug === 'during-camp') {
        return duringCamp;
    }
    return afterCamp;
}
</script>

<template>
    <Content>
        <head-title :value="`Information Hub`" />
        <Header>
            <HeaderTitle>Information Hub</HeaderTitle>
        </Header>
        <div class="p-4 mt-10 mb-10">
            <div class="mb-4 text-center text-2xl font-medium">
                Your Ultimate NYSC Guide at Every Step
            </div>
            <div class="text-center">
                Explore expert tips, essential guides, and valuable resources tailored to help you navigate your NYSC journey with ease.
            </div>
        </div>
        <div class="p-4">
            <div class="flex flex-wrap justify-center gap-4">
                <RouterLink
                    v-for="category in categories.data"
                    :key="category.id"
                    :to="`/information-hub/${category.slug}`"
                    class="block bg-gray text-gray-foreground rounded-lg p-6 w-full md:w-[300px]">
                    <div class="flex justify-center items-center size-20 mx-auto mb-2">
                        <img :src="getImage(category.slug)" :alt="category.name" class="w-full h-auto">
                    </div>
                    <div class="text-lg font-medium text-center">{{ category.name }}</div>
                    <div class="text-sm text-gray-foreground/90 text-center mt-2">{{ category.help_text }}</div>
                </RouterLink>
            </div>
        </div>
        <div class="h-[60px]"></div>
    </Content>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
    as: {
        type: String,
        default: 'div',
    },
});

const listItemProps = inject('app-list-item-props');
</script>

<template>
    <div class="shrink-0" :class="{ 'my-auto': listItemProps.alignCenter }">
        <slot></slot>
    </div>
</template>

import { useRoute } from 'vue-router';

import axios from '@/axios';
import echo from '@/plugins/echo';
import { useStore } from '@/store';
import { useEmitter } from "@/plugins/emitter";

export const useChannelListener = () => {
    const emitter = useEmitter();
    const store = useStore();
    const route = useRoute();
    let echoChannel = null;

    return {
        listen({ channel, postPublished }) {

            const onPostPinned = (event) => {
                const channelId = event.id;

                if (channelId !== channel.id) {
                    return;
                }

                channel.pinned_posts_count = (channel.pinned_posts_count || 0) + 1;
            }

            const onPostUnpinned = (event) => {
                const channelId = event.id;

                if (channelId !== channel.id) {
                    return;
                }

                channel.pinned_posts_count = Math.max(0, (channel.pinned_posts_count || 0) - 1);
            }

            const onEchoPostPublished = (event) => {
                axios.get(`/api/posts/${event.id}`).then(response => {
                    onPostPublished(response.data.data);
                });
            }

            const onPostPublished = (post) => {
                if (post.channel.id !== channel.id) {
                    return;
                }

                if (post.user.id !== store.state.user?.id && route.path !== `/channels/${channel.handle}` && channel.user_membership) {
                    const unseenPosts = channel.user_membership.total_unseen_posts || 0;
                    channel.user_membership.total_unseen_posts = unseenPosts + 1;
                }

                channel.last_post_published_at = post.created_at;

                if (typeof postPublished === 'function') {
                    postPublished(post);
                }
            }

            echoChannel = echo.private(`channels.${channel.id}`);
            echoChannel.listen('.post:published', onEchoPostPublished);
            emitter.on('.post:published', onPostPublished);
            emitter.on('.channel:post-pinned', onPostPinned);
            emitter.on('.channel:post-unpinned', onPostUnpinned);

            return {
                off() {
                    echoChannel?.stopListening('.post:published', onEchoPostPublished);
                    emitter.off('.post:published', onPostPublished);
                    emitter.off('.channel:post-pinned', onPostPinned);
                    emitter.off('.channel:post-unpinned', onPostUnpinned);
                }
            }
        },
    }
}

export const usePostListener = ({
    getPost,
    getPosts = null,
    postDeletedCb = null,
    postHiddenCb = null,
    postReportedCb = null,
}) => {
    const emitter = useEmitter();
    let globalChannel = null;

    const onPostPinned = (data) => {
        const post = getPost(data.id);

        if (!post) {
            return;
        }

        post.pinned_at = data.pinned_at;
    }

    const onPostUnpinned = (data) => {
        const post = getPost(data.id);

        if (!post) {
            return;
        }

        post.pinned_at = null;
    }

    const onUpdated = (data) => {
        const post = getPost(data.id);

        if (!post) {
            return;
        }

        Object.keys(data).forEach((key) => {
            post[key] = data[key];
        });
    }

    const onPostDeleted = (data) => {
        const post = getPost(data.id);

        if (!post) {
            return;
        }

        post.deleted = true;

        if (typeof postDeletedCb === 'function') {
            postDeletedCb(post);
        }
    }

    const onPostPreferenceSaved = (data) => {
        const post = getPost(data.post_id);

        if (!post) {
            return;
        }

        post.preference = data;
    }

    const onPostViewed = (data) => {
        const post = getPost(data.id);

        if (!post) {
            return;
        }

        post.total_views += 1;
    }

    const onPostHidden = (data) => {
        const post = getPost(data.id);
        if (!post) {
            return;
        }

        post.status = 4;

        if (typeof postHiddenCb === 'function') {
            postHiddenCb(post);
        }
    }

    const onPostReported = (data) => {
        const post = getPost(data.id);

        if (!post) {
            return;
        }

        post.reported = true;

        if (typeof postReportedCb === 'function') {
            postReportedCb(post);
        }
    }

    const onUserBlocked = (event) => {
        if (typeof getPosts !== 'function') {
            return;
        }

        const posts = getPosts().filter(post => {
            if (event.anonymous) {
                return event.source_type === 'post' && event.source_id === post.id;
            }

            if (post.anonymously) {
                return false;
            }

            if (!post.user) {
                console.log('post.user is null', post.anonymous, post.id, post.content, post);
            }

            return post.user.id === event.user.id;
        });

        posts.forEach(post => {
            post.blocked = true;
        });
    }

    return {
        on() {
            globalChannel = echo.channel('global');

            emitter.on('.post:pinned', onPostPinned);
            emitter.on('.post:unpinned', onPostUnpinned);
            emitter.on('.post:updated', onUpdated);
            emitter.on('.post:deleted', onPostDeleted);
            emitter.on('.post:reported', onPostReported);
            emitter.on('.post-preference:saved', onPostPreferenceSaved);
            emitter.on('.post:hidden', onPostHidden);

            emitter.on('.user:blocked', onUserBlocked);

            globalChannel.listen('.post:viewed', onPostViewed);
            globalChannel.listen('.post:deleted', onPostDeleted);
            globalChannel.listen('.post:hidden', onPostHidden);
        },
        off() {
            emitter.off('.post:pinned', onPostPinned);
            emitter.off('.post:unpinned', onPostUnpinned);
            emitter.off('.post:updated', onUpdated);
            emitter.off('.post:deleted', onPostDeleted);
            emitter.off('.post:reported', onPostReported);
            emitter.off('.post-preference:saved', onPostPreferenceSaved);
            emitter.off('.post:hidden', onPostHidden);

            emitter.off('.user:blocked', onUserBlocked);

            globalChannel?.stopListening('.post:viewed', onPostViewed);
            globalChannel?.stopListening('.post:deleted', onPostDeleted);
            globalChannel?.stopListening('.post:hidden', onPostHidden);
        }
    }
}
